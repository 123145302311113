@import "styles/Colors.scss";
@import "styles/Typography.scss";
@import "styles/BorderRadius.scss";
@import "styles/BoxShadows.scss";

.navbar-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  z-index: 9;
  background-color: var(--color-surface-primary);
  border-bottom: 1px solid var(--color-border-primary);
  padding: 0 16px;

  .sidebar-toggle {
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.sidebar-open {
      position: absolute;
      left: 220px;
      bottom: 7px;
    }

    &.sidebar-closed {
      margin-left: 50px;
    }

    @media screen and (max-width: 600px) {
      &.sidebar-closed {
        margin-left: 0;
      }
    }

    .icon-dark {
      fill: var(--color-neutral-10);
    }

    .icon-white {
      fill: var(--color-primitives-white-white);
    }
  }

  .navbar-actions {
    gap: 8px;
  }

  .user-menu-container {
    position: relative;

    .user-menu-trigger {
      border-radius: var(--border-radius-lg);

      .dropdown-icon {
        margin-left: 0px;
        color: var(--color-neutral-10);
      }

      .user-avatar {
        border-radius: 100px;
        border: 4px solid var(--color-primitives-white-white);
      }

      &:hover .user-avatar,
      &.menu-open .user-avatar {
        border: 4px solid var(--color-border-primary);
      }
    }
  }
}

.user-info {
  .user-email {
    color: var(--color-text-body-primary) !important;
  }
}

.divider-bottom {
  height: 1px;
  background-color: var(--color-border-primary);
  margin: 8px 0;
  width: 100%;
}
