/* CompanyFeedbackForm & ProductFeedbackForm CSS */

.anonymousText {
  margin-bottom: 16px !important;
}

.successTitle {
  margin-bottom: 16px !important;
  font-size: 18px !important;
}

.success-back-button {
  margin-right: auto;
  margin-left: auto;
}

.buttonsBox {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

/* Feedback Modal.js CSS */

.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0 !important;
}

.modalContent {
  background-color: #ffffff;
  box-shadow:
    0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%),
    0px 1px 8px 0px rgb(0 0 0 / 12%);
  padding: 40px;
  max-width: 500px;
  min-height: 500px;
  width: 100%;
  text-align: center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
}

.gridItem {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Modalbutton {
  margin: 8px !important;
  padding: 30px !important;
  border-color: var(--temp-color-primitives-primary-800) !important;
  border-radius: 12px !important;
}

.Modalbox {
  display: block;
}

.ModalbuttonText {
  font-family: Poppins-Medium !important;
  margin-bottom: 10px !important;
  color: var(--temp-color-primitives-primary-800) !important;
}

.ModalbuttonSubtext {
  display: block;
  color: #2c2d3e;
}
