.employee-card-d1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  top: 47px;
  padding-right: 33px;
}

.employee-card-d2 {
  position: relative;
  width: 1176px;
  margin: auto;
}

.employee-card-avatar {
  position: absolute;
  bottom: -30px;
  width: 100px !important;
  height: auto !important;
  border: 0.5px solid white;
}

.employee-details-glove-img {
  width: 109px;
  height: 81px;
}
