@import "styles/Typography.scss";

.employee-empty-screen-icon-wrapper {
  background-color: var(--color-surface-primary-hover);
  border-radius: 77px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-icon-secondary);
  font-size: 20px !important;
  margin-bottom: 10px;
}

.employee-empty-screen-text-wrapper {
  @extend .para-body-s-regular;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  text-align: center;
  color: var(--color-text-body-primary);
}

.first-payment-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  border: 1px solid var(--color-border-primary);
  border-radius: var(--border-radius-sm);
  padding: 12px 0px 12px 16px;
  gap: 14px;
  margin-top: 11px;
}
