@import "styles/Typography.scss";
@import "styles/Colors.scss";

.onboarding-avatar-mobile-menu {
  .MuiPaper-root {
    padding: 24px !important;
    max-width: none !important;
    width: 100% !important;
    left: 0 !important;
    top: 12px !important;
  }

  .MuiList-root {
    padding: 0px !important;
    margin: 0px !important;
  }
  .MuiMenuItem-root {
    padding: 0px !important;
    margin: 0px !important;
  }
}

.onboarding-avatar-desktop-menu {
  margin-top: 32px;
  .MuiPaper-root {
    width: 11rem !important;
  }
}

.onboarding-avatar-menu-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: var(--border-radius-full);
  &:hover {
    background-color: var(--color-surface-primary-hover) !important;
  }
}

.onboarding-avatar-menu-icon {
  color: var(--color-icon-secondary);
}

.onboarding-avatar-menu {
  margin-top: 33px;
}

.onboarding-avatar-menu-icon-logout {
  color: var(--color-border-error);
}

.onboarding-avatar-menu-item {
  padding: 6px 16px;
  gap: 12px;
  width: 164px;
  display: flex;
}

.onboarding-avatar-menu-icon-button {
  padding: 0 !important;
  margin: 0 !important;
}

.onboarding-avatar-mobile-menu {
  margin-top: 36px;
}

.onboarding-mobile-menu-profile {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
}

.onboarding-mobile-menu-profile {
  .mobile-menu-avatar {
    background-color: var(--color-primitives-neutral-300) !important;
  }
}

.avatar-menu-desktop {
  .avatar-desktop-menu-avatar {
    background-color: var(--color-primitives-neutral-300) !important;
  }
}
