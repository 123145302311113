.pending-leaves-container-emp {
  border-radius: 12px;
  background: #fbfaff;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 3rem;
  padding: 1rem 3.5rem;
  margin-top: 1rem;
}

.note-expand-icon {
  padding: 10px;
  margin-top: 0 !important;
}

.note-expand-icon:hover {
  cursor: pointer;
  background: var(--color-primitives-primary-50);
  clip-path: circle();
}

.count-text-emp {
  text-align: center;
  margin: auto;
  margin-bottom: 0.5rem;
  margin-top: -1rem;
}
