@import "styles/Typography.scss";
@import "styles/Colors.scss";

.table-component {
  .MuiTableCell-root,
  .MuiTablePagination-root,
  .MuiTableSortLabel-root,
  .table-cell-content,
  .MuiTypography-root,
  .MuiButtonBase-root {
    font-family: var(--font-family-paragraph) !important;
  }

  .MuiTablePagination-select,
  .MuiTablePagination-displayedRows {
    @extend .para-body-s-regular;
    color: var(--color-text-body-secondary) !important;
  }

  .Mui-disabled {
    color: var(--color-surface-action-primary-disabled) !important;
  }

  .MuiTablePagination-displayedRows {
    margin-left: -30px !important;
    margin-right: -25px !important;
  }

  .table-component-pagination {
    display: flex !important;
    padding: 2px 0px !important;
    justify-content: flex-end !important;
    align-items: center !important;
    gap: 26px !important;
    align-self: stretch !important;
  }

  .MuiTablePagination-toolbar {
    margin-right: -10px !important;
  }

  .MuiTablePagination-selectLabel {
    @extend .para-body-s-regular;
    color: var(--color-text-body-primary) !important;
    margin-right: -10px !important;
  }

  .MuiTablePagination-select {
    padding-left: 8px !important;
    margin-left: 0px !important;
    margin-top: 2px;
  }

  .MuiTablePagination-displayedRows {
    padding: 0px 26px;
  }

  .table-cell-content {
    color: var(--color-text-headings-primary);
    font-size: var(--font-size-md) !important;
    font-style: normal;
    font-weight: var(--font-weight-regular) !important;
    line-height: var(--line-height-md);
    white-space: nowrap;

    > * {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      font-style: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
  }

  .table-body-row,
  .table-cell-regular .table-cell-small {
    color: var(--color-primitives-black-black) !important;
    font-size: var(--font-size-md) !important;
    font-style: normal !important;
    font-weight: var(--font-weight-regular) !important;
    line-height: var(--line-height-lg) !important;
    border-bottom: 1px solid var(--color-border-primary) !important;
    white-space: nowrap !important;

    .MuiTypography-root {
      font-size: inherit;
    }
  }

  .table-body-row {
    &.no-bottom-border {
      border-bottom: 3px solid white !important;
    }
  }

  .table-cell-small {
    padding: 6px 16px !important;
  }

  .table-head-small,
  .table-head-regular {
    background-color: var(--color-brand-primary-grey-50) !important;
    max-height: 52px !important;
    font-weight: var(--font-weight-medium) !important;
  }

  .table-head-small {
    padding: 6px 16px !important;
    height: 32px !important;
  }

  .table-body-row {
    background-color: var(--color-primitives-white-white) !important;
    height: 56px !important;
    max-height: 56px !important;
  }

  .MuiTablePagination-root {
    overflow: visible;
  }

  .MuiTablePagination-actions .MuiButtonBase-root {
    color: var(--color-primitives-black-black);
  }

  .MuiTablePagination-actions .MuiButtonBase-root:disabled {
    cursor: not-allowed !important;
  }

  .table-toolbar {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-primitives-white-white);
  }

  .MuiTablePagination-root {
    .MuiToolbar-root {
      padding-inline: 0 !important;
      padding: 0px 20px !important;
    }
  }

  .MuiSelect-icon {
    color: var(--color-primitives-black-black);
  }

  .MuiTableSortLabel-icon {
    color: var(--color-icon-secondary) !important;
  }
}

.menu-divider {
  margin: 8px 0;
  border: none;
  border-top: 1px solid var(--color-border-primary);
}

.menu-item-error {
  color: var(--color-primitives-danger-800) !important;

  svg {
    color: var(--color-primitives-danger-800) !important;
  }
}

.action-menu {
  width: 160px !important;
  border-radius: var(--border-radius-sm);
  box-shadow: 0px 4px 8px 0px var(--color-elevation-default) !important;
}

.action-menu-item {
  padding: 4px 16px !important;
  color: var(--color-primitives-black-black);
  font-size: var(--font-size-md) !important;
  font-family: var(--font-family-paragraph) !important;
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular) !important;
  line-height: var(--line-height-lg);
}

.delete-item {
  color: var(--color-primitives-danger-600) !important;
}

.three-dots {
  color: var(--color-icon-secondary);
}

.action-icon-color {
  color: var(--color-icon-secondary) !important;
}

.action-icon-color-disabled {
  color: var(--color-surface-action-primary-disabled) !important;
}

.no-cell-padding {
  padding: 0 !important;
}
