@import "styles/Typography.scss";
@import "styles/Colors.scss";

.onboarding-layout {
  display: flex;
  flex-direction: row;
  width: 100vw;
  overflow: hidden;
  min-height: 100dvh;
  background-color: var(--color-surface-primary);

  .onboarding-layout-sidebar {
    padding: 32px 20px;
    border-right: 1px solid var(--color-border-primary);
    display: flex;
    flex-direction: column;
  }

  .onboarding-layout-header-logo-image {
    height: 28px;
    width: 84px;
    object-fit: cover;
  }

  .onboarding-layout-content-container {
    flex: 1;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }

  .onboarding-layout-content-header {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .onboarding-layout-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-inline: 16px;
    height: 48px;
    border-bottom: 1px solid var(--color-border-primary);
  }

  .onboarding-layout-header-logo {
    display: none;
  }

  .onboarding-layout-content-sidebar {
    display: none;
  }

  .onboarding-layout-content-title {
    @extend .heading-h2-semibold;
    display: flex;
    padding: 16px 20px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid var(--color-border-primary);
  }

  &-footer {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 24px;
    background: var(--color-surface-primary);
    border-top: 1px solid var(--color-border-primary);
    margin: 24px -24px -24px -24px;
    display: flex;
    justify-content: flex-end;
    z-index: 10;
    margin-top: auto;
  }

  @media (max-width: 900px) {
    flex-direction: column;

    .onboarding-layout-sidebar {
      display: none;
    }

    .onboarding-layout-header-logo {
      padding-top: 10px;
      padding-left: 16px;
      display: block;
    }

    .onboarding-layout-header {
      padding-inline: 0px;
      justify-content: space-between;
    }

    .onboarding-layout-content-title {
      display: none;
    }

    .onboarding-layout-content-sidebar {
      padding: 20px;
      border-bottom: 1px solid var(--color-border-primary);
      display: block;
    }

    .onboarding-layout-content-header {
      border-bottom: none;
      padding-right: 16px;
    }

    .onboarding-layout-content-container {
      flex: 1;
      overflow-y: auto;
      min-height: 0;
    }
  }
}
