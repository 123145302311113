.churned-modal {
  padding-inline: 10px;
  font-family: "Poppins", sans-serif;
}

.title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.churned-modal-title {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-primitives-primary-900);
}

.churned-modal-content {
  margin-bottom: 20px;
}

.churned-modal-employee-list {
  max-height: 200px;
  min-height: 100px;
  overflow-y: auto;
  margin-block: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.churned-modal-employee-item {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-around;
  gap: 20px;
}

.churned-modal-avatar {
  margin-right: 10px;
}

.churned-modal-employee-info {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.churned-modal-employee-name {
  font-weight: 600;
  color: var(--color-primitives-primary-900);
}

.churned-modal-employee-email {
  margin: 0;
  color: var(--color-primitives-primary-600);
  font-size: 14px;
  font-weight: 400;
}

.churned-modal-employee-item:last-child {
  border-bottom: none;
}

.churned-modal-warning {
  color: #d32f2f;
  font-weight: bold;
}

.churned-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.churned-modal-cancel,
.churned-modal-confirm {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 25px;
}

.churned-modal-cancel {
  background-color: transparent;
  color: var(--color-primitives-primary-600);
}

.churned-modal-cancel:hover {
  background-color: #e3f2fd;
}

.churned-modal-confirm {
  background-color: #d32f2f;
  color: white;
}

.churned-modal-confirm:hover {
  background-color: #c62828;
}
