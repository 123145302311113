.container {
  border-radius: 12px;
  margin: auto;
  overflow: hidden;
  font-family: sans-serif;
  padding: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid var(--color-primitives-primary-50);
  align-items: center;
  padding: 0px 16px;
}

.notificationDot {
  height: 12px;
  width: 12px;
  background-color: #ff5f56;
  border-radius: 50%;
}

.list {
  list-style-type: none;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  margin: 0;
  padding: 8px;
  margin-top: 10px;
}

.listItem {
  padding: 7px 16px;
  border-bottom: 1px solid #e1e4e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.title {
  font-weight: 400;
  font-size: 14px;
  font-family: Poppins-SemiBold;
  line-height: 20px;
  /* padding: 7px 16px; */
  /* font-weight: 700; */
}

.count {
  font-size: 14px;
  font-family: "Poppins-SemiBold !important";
  line-height: 20px;
  /* padding: 4px 8px; */
  /* font-weight: 700; */
}
