.last-login-container {
  display: flex;
  padding: 5px 8px;
  align-items: start;
  justify-content: center;
  gap: 4px;
  border-radius: 27px;
  background: hsl(236, 100%, 97%);
  width: max-content;
  margin: 8px auto auto auto;
}

.employee-last-active {
  align-items: center;
}

.last-login-icon {
  color: var(--color-brand-primary-blue-600);
  width: 18px !important;
  height: 18px !important;
}

.last-login-data {
  color: #212231;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
}

.last-login-email {
  color: var(--color-primitives-primary-600);
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  text-decoration-line: underline;
}
