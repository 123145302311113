.holiday-label {
  padding-top: 6px;
  text-transform: capitalize;
  opacity: 0.5;
}

.list-container {
  border: 1px solid var(--color-primitives-primary-50);
  padding: 6px, 14px, 6px, 6px;
  gap: 12px;
  border-radius: 12px;
  margin-top: 10px;
}

.list-leaves-icon {
  padding: 6px;
}
