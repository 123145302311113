.pending-leaves-card-container {
  border-radius: 12px;
  background: #fefdff;
  border: 1px solid var(--color-primitives-primary-100);
  width: 100%;
}

.pending-leaves-card-container-collapsed {
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid var(--color-primitives-primary-50);
  width: 100%;
}

.pending-leaves-profile-icon {
  margin: 0.5rem 1rem;
}

.pending-leaves-profile-icon:hover {
  background-color: var(--color-primitives-primary-50);
  clip-path: circle();
}

.pending-leave-reason {
  color: var(--color-primitives-primary-600);
  font-family: Poppins-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-top: 0.3rem; */
}

.pending-leave-duration {
  display: flex;
  gap: 10px;
}

.pending-leave-date {
  color: #292a3d;
  font-family: Poppins-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pending-leave-arrow {
  transform: rotate(180deg);
}

.pending-leaves-time-icon {
  padding: 4.5px 18px 4.5px 16px;
}

.chip-leaves-pending-label {
  color: var(--color-primitives-primary-600);
  font-family: Poppins-Medium;
  font-size: 15px;
}

.chip-leaves-pending-icon {
  padding-left: 0.5rem;
  transform: scale(1.3);
}

.pending-leave-chip {
  align-self: center;
  margin-right: 17px;
}

.pending-timeoff-details-wrapper {
  margin-block: 9px;
}

.pending-timeoff-details {
  display: flex;
  margin-left: 1rem;
  align-items: center;
}

.pending-timeoff-details-text {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 12px;
  width: 28%;
  line-height: 18px;
  margin: 5px 0;
}

.scheduled-timeoff-details-text {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 12px;
  width: 50%;
  line-height: 18px;
}

.scheduled-timeoff-details-text-admin {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 12px;
  width: 17%;
  line-height: 18px;
}

.pending-timeoff-comments {
  padding: 0.6rem 1rem;
}

.pending-timeoff-comments-img {
  clip-path: circle();
  width: 4rem;
  padding: 1rem;
}

.pending-timeoff-comment {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 14px;
  padding: 0.6rem 1rem;
}

.pending-timeoff-comment-author {
  color: #c7c5d0;
  font-family: Poppins-Regular;
  font-size: 11px;
  padding-left: 1rem;
}

.pending-timeoff-comments-container {
  display: flex;
}

.pending-timeoff-request-date {
  color: #7f7e82;
  font-family: Poppins-Regular;
  font-size: 12px;
  padding: 1.2rem 0.5rem;
}

.request-date {
  opacity: 0.5;
}

.replacement-button {
  width: 229px;
  height: 38px;
  padding: 12px 20px 12px 20px !important;
  gap: 8px;
  border-radius: 42px 0px 0px 0px;
  background-color: white;
  border: 1px solid var(--color-primitives-primary-100) !important;
  color: var(--temp-color-primitives-primary-800);
  font-family: Poppins;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  letter-spacing: 0.1px !important;
  text-align: center;
}

.replacement-request-button {
  height: 24px;
  background: white;
  border-radius: 12px 0px 0px 0px;
  border: 1px solid #bfc2ff;
  width: 173px !important;
  font-family: Poppins;
  font-size: 12px !important;
  font-weight: 400;
  padding-inline: 0px !important;
  padding-top: 3px !important;
  margin-right: 10px;
  line-height: 18px !important;
}
