.ep-peers-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 752px;
  margin: 10px auto;
}
.ep-peers-list-item {
  border-radius: 20px;
  background-color: #ffffff;
  width: 175px;
  height: 227px;
}
.ep-peers-list-item img {
  width: 150px;
  height: 120px;
  object-fit: cover;
  border-top-right-radius: 19px;
  border-top-left-radius: 19px;
}

.ep-peers-card-details {
  padding: 26px 23px 20px 10px;
}
.ep-peers-card-name {
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #000000;
  margin-bottom: 10px;
  width: 110px;
  white-space: nowrap;
}
.ep-peers-card-title {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 11px;
  line-height: 103%;
  color: var(--color-primitives-primary-600);
}
