.outer_container_report {
  margin: 0 auto;
  background: #fefefe;
  min-height: 500px;
}

.time_off_report_container {
  padding: 16px;
  background-color: #fefefe;
  border-radius: 24px;
  border: 1px solid var(--color-primitives-primary-100);
  width: 1300px;
}

.table-container {
  scrollbar-color: #66689980 #fefefe;
  scrollbar-width: thin;
}

.table-container::-webkit-scrollbar {
  height: 12px;
}

.table-container::-webkit-scrollbar-track {
  background: #fefefe;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #66689980;
  border-radius: 10px;
  border: 3px solid #fefefe;
}

.time_off_report_top_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fefefe;
}

.filter-container {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.date-picker,
.country-select {
  width: 200px;
}

.report-table {
  border-collapse: unset !important;
  background-color: #fefefe;
}

.report-table th,
.report-table tr {
  border-top: none !important;
}

.report-table th {
  text-transform: uppercase;
}

.report-table th,
.report-table td {
  padding: 8px 10px;
  border: 0.5px solid var(--color-primitives-primary-100);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.report-table .sub_columns_cells th {
  background-color: #fafaff;
  color: #666899;
  text-align: center;
  min-width: 70px;
}

.report-table td {
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #292a3d;
}

.report-table td:first-child {
  text-align: left;
}

.time_off_report_pagination {
  display: flex;
  justify-content: end;
}

.time_off_report_pagination .MuiPaginationItem-root,
.time_off_report_pagination .MuiPaginationItem-ellipsis {
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #212b36;
  border-radius: 8px;
  background: #fff !important;
  padding: 5px 4px;
}

.time_off_report_pagination .Mui-selected {
  color: var(--color-primitives-primary-600);
  border: 2px solid var(--color-primitives-primary-600);
}

.date-range-button {
  display: flex;
  text-align: center;
  justify-content: space-between;
  font-family: Inter;
  padding: 5px 12px;
  border: 1px solid var(--color-primitives-primary-50);
  border-radius: 24px;
  cursor: pointer;
  min-width: 10rem;
  color: #292a3d;
  font-size: 13px;
  height: 32px;
  background-color: #fbfaff;
}

.table-wrapper {
  overflow-x: auto;
  width: 100%;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  z-index: 1;
}

.left-sticky-col {
  left: 0;
}

.report-table th,
.report-table td {
  white-space: nowrap;
}

.report-table .sub_columns_cells .sticky-col {
  background-color: white;
  z-index: 2;
}

.no-data-reports-heading {
  color: var(--Neutral-10, #14151f);
  font-family: Poppins;
  font-size: 22px;
}

.no-data-reports-subheading {
  color: #666899;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
}

.no-data-reports-container {
  display: flex;
  justify-content: center;
  height: 600px;
  align-items: center;
  flex-direction: column;
}
