.employee-paystubs-details-dA {
  margin: auto;
  background-color: #fbfaff;
  display: flex;
  justify-content: space-between;
  width: 1076px;
  height: 80px;
  align-content: center;
  flex-wrap: wrap;
}

.employee-paystubs-details {
  background-color: #fbfaff;
  height: calc(100vh - 46px);
}

.peers-wrapper {
  width: 1076px;
  margin: auto;
}
.employee-paystubs-details-dAA {
  display: flex;
}

.employee-paystubs-page-container {
  width: 1256px;
  margin: 1rem auto;
}

.employee-paystubs-details-dAA img {
  object-fit: contain;
  margin-right: 10px;
}

.employee-paystubs-details-slips {
  color: var(--neutral-20, #292a3d);
  font-family: Poppins-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.employee-paystubs-details-edit {
  color: var(--neutral-20, #292a3d);
  font-family: Poppins-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.employee-paystubs-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #ffffff;
  border-radius: 20px;
  width: 1076px;
  margin: auto;
}

.employee-payslip-details-container {
  width: 1256px;
  margin: auto;
}

.emp-payslip-container {
  width: 1045px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--color-primitives-primary-100);
  background: #fff;
  margin: auto;
}

.emp-payslip-header-container {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  border-radius: 16px;
  background: var(
    --Gradient-light,
    linear-gradient(221deg, #ffefd3 4.69%, var(--color-primitives-primary-100) 41.39%, #b4fff2 120.42%)
  );
}

.emp-payslip-edge-logo {
  display: flex;
  width: 72px;
  height: 72px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--Secondary-100, #fff);
  box-shadow: 0px 1px 14px 0px rgba(2, 0, 110, 0.12);
}

.emp-payslip-edge-logo div {
  display: flex;
}

.emp-payslip-edge-logo img {
  width: 66px;
  height: 35px;
}

.emp-payslip-title {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.emp-payslip-month {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.emp-payslip-download-btn {
  transition: 0.3s ease;
  display: flex;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 42px;
  background: var(--Primary-100, #fff);
  align-self: center;
  color: var(--material-theme-surfaces-dark-surface1, #14151f);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.emp-payslip-download-btn:hover {
  box-shadow:
    0px 1px 1px 0px rgba(2, 0, 110, 0.22),
    0px 1px 6px 0px rgba(2, 0, 110, 0.25);
}

.emp-payslip-download-btn img {
  width: 18px;
  height: 18px;
  align-self: center;
}

.personal-payroll-info-container {
  display: flex;
  padding: 16px 20px;
  align-items: flex-start;
  gap: 16px;
  margin-top: 12px;
  border-radius: 16px;
  background: var(--Primary-99, #fbfaff);
}

.payslip-summary-container {
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  /* gap: 16px; */
  margin-top: 12px;
  border-radius: 16px;
  background: var(--color-primitives-primary-50);
}

.personal-payroll-info-container div {
  width: 50%;
}

.personal-payroll-info-title {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.payroll-info-container {
  display: flex;
  width: 100% !important;
}

.payroll-info-container div {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-top: 8px;
  width: 50%;
}

.payslip-details-section1-heading {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  width: 500px;
}

.payslip-details-section2-heading {
  color: var(--Neutral-20, #292a3d);
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 200px;
}

.payslip-details-section3-heading {
  color: var(--Neutral-20, #292a3d);
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 265px;
}

.payslip-details-heading {
  display: flex;
  margin-top: 0.5rem;
  border-bottom: 2px solid #000;
  gap: 1rem;
  height: 44px;
}

.payslip-details-heading div {
  display: grid;
  align-items: center;
}

.payslip-details {
  display: flex;
  gap: 1rem;
}

.payslip-details-data {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 36px;
  display: grid;
  align-items: center;
  width: 500px;
  text-align: right;
}

.payslip-details-total {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: 500px;
  text-align: right;
  margin-top: 10px;
  height: 30px;
  display: grid;
  align-items: center;
}

.payslip-summary-details {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.payslip-summary-details .text-left {
  width: 70%;
}

.payslip-details-net-amount {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.payslip-details-divider {
}
