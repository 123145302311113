@import "styles/Typography.scss";
@import "styles/Colors.scss";

.onboarding-add-ach-payment-main-div {
  min-width: 60% !important;
  padding: 24px 7rem 75px 20px;

  @media (min-width: 1680px) {
    max-width: 532px !important;
    padding: 24px 56px 75px 20px;
  }

  @media (max-width: 900px) {
    width: 100% !important;
    padding: 20px;
    padding-bottom: 60px;

    form {
      width: 100%;

      .flex {
        &:not(.flex-nowrap) {
          flex-direction: column;
          gap: 16px;
        }

        > div {
          width: 100%;
        }
      }
    }

    .add-ach-note-wrapper {
      padding-bottom: 66px;
    }
  }
}

.onboarding-input-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-group-add-payment {
  display: flex;
  gap: 16px;
  margin-bottom: 0.75rem;
  margin-top: 24px;
}

.onboarding-lock-icon {
  color: var(--color-brand-primary-grey-400);
  width: 16px !important;
  height: 16px !important;
  margin-right: 5px;
}

.information-secure-text {
  @extend .para-body-s-regular;
  @extend .color-text-body-primary;
  margin-bottom: 12px;
  white-space: nowrap;
}

.add-ach-note-wrapper {
  padding-top: 13px;
}

.onboarding-bottom-button {
  border-top: 1px solid var(--color-border-primary);
  padding: 12px 0 12px 0;
  display: flex;
  width: calc(100% - 320px);
  justify-content: flex-end;
  position: fixed;
  bottom: 0px;
  background: white;
}

.onboarding-bottom-button-container {
  padding: 0 16px;
}

.onboarding-add-payment-label-icon {
  margin-bottom: -4px;
  width: 20px !important;
  height: 20px !important;
  color: var(--color-icon-secondary);
  cursor: pointer;
}

@media (max-width: 768px) {
  .onboarding-bottom-button {
    width: 100%;
    bottom: 0px;
    padding: 12px 0px 10px 0px;
    z-index: 1;
  }
}

.onboarding-add-payment-main-div {
  display: flex;
  justify-content: space-between;
}

.onboarding-add-ach-payment-container {
  width: 100%;
}

@media (max-width: 900px) {
  .onboarding-add-payment-main-div {
    flex-direction: column-reverse;
  }

  .input-group-add-payment {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
