.eye-icon {
  color: var(--color-icon-secondary);
}

.rebrand-phase1 .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.rebrand-phase1 .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.rebrand-phase1 .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline,
.rebrand-phase1 .MuiOutlinedInput-root.Mui-focused.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--color-primitives-primary-600) !important;
}
