@import "styles/Typography.scss";

.payment-breakdown-wrapper {
  padding: 24px;
  width: 460px;
  background-color: var(--color-surface-primary-hover);
  height: 100vh;
}

.payment-breakdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: white;

  .payment-breakdown-top {
    display: flex;
    height: 8px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background: var(--color-surface-secondary-inverse);
  }

  .payment-breakdown-header {
    width: 100%;
  }

  .payment-breakdown-title {
    @extend .heading-h4-semibold;
    padding: 10px 16px;
    border-bottom: 1px solid var(--color-border-primary);
  }

  .amount-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid var(--color-border-primary);
  }

  .amount-fee-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }

  .amount-fee-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-text-body-primary);
  }

  .amount-fee-item-icon {
    width: 14px;
    height: 14px;
    margin-left: 4px;
    color: var(--color-text-body-primary);
  }
}

@media (max-width: 900px) {
  .payment-breakdown-wrapper {
    width: 100% !important;
    height: 100%;
  }
}
