@import "styles/Typography.scss";
@import "styles/Colors.scss";

.help-success-modal-title {
  display: flex;
  width: 444px;
  flex-direction: column;
  align-items: flex-start;
}

.help-success-modal-icon {
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 41px;
  background-color: var(--color-surface-success);
  svg {
    width: 28px;
    height: 28px;
    color: var(--color-primitives-success-600);
  }
}

.help-success-modal-heading {
  @extend .heading-h2-semibold;
  margin-top: 11px;
  white-space: normal !important;
}
