@import "styles/Typography.scss";
@import "styles/Colors.scss";

.onboarded-customer-view-container {
  .welcome-heading {
    @extend .heading-h4-medium;
    font-family: Poppins;
    margin-bottom: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .info-tile-container {
    margin-top: 16px;
    margin-bottom: 36px;
  }

  .onboarded-view-description-text {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: var(--color-neutral-varian-60);
  }

  .tile-link {
    @extend .heading-h5-regular;
    color: var(--color-text-action);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration: none;
  }
}
