.common-grid-container {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid var(--color-primitives-primary-100);
  border-radius: 26px;
}
.common-grid-header {
  margin: 15px;
}
.common-grid-content {
  margin: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.grid-content-payslip {
  display: flex;
  flex-direction: column;
}
.common-grid-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid-row-payslip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.1s;
}

.grid-row-payslip:hover {
  background-color: var(--color-primitives-primary-50);
  cursor: pointer !important;
}

.grid-row-payslip:last-child:hover {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.grid-row-payslip + .grid-row-payslip {
  border-top: 1px solid var(--color-primitives-primary-50);
}

.grid-row-payslip:hover .employee-stubs-grid-cella img {
  background: white;
}

.employee-stubs-grid-cella img {
  background: var(--color-primitives-primary-50);
  width: 40px;
  height: 40px;
  border-radius: 19px;
  padding: 10px;
  gap: 10px;
}
