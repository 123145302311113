.on-leave-employees {
  padding: 0px;
  padding-bottom: 20px;
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
  height: 60px;
  padding: 0px 14px;
  border-bottom: 1px solid #b2b4e2;
}

.date-badge {
  width: 38px;
  height: 38px;
  background-color: var(--temp-color-primitives-primary-800);
  position: absolute;
  border-radius: 12px;
}

.date-info {
  padding-left: 50px;
}

.date-info h2 {
  color: #292a3d;
  font-family: Poppins-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.15px;
  margin: 0;
  line-height: 24px;
}

.date-info p {
  color: #3d3e5c;
  font-family: Poppins-Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.navigation-arrows {
  display: flex;
  gap: 10px;
}

.previous-btn,
.next-btn {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.employees-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 14px;
  column-gap: 24px;
  overflow-y: auto !important;
  max-height: 320px !important;
  padding-right: 14px;
  margin: 0px 16px 0px 0px;
  padding-left: 16px;
}

.employee-item {
  flex: 1 1 45%;
  display: flex;
  justify-content: flex-start;
  border-radius: 12px;
  border: 1px solid var(--color-primitives-primary-50);
}

.employee-item.right {
  justify-content: flex-end;
}

.employee-info {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 6px 14px 6px 6px;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  height: 75px;
}

.employee-avatar {
  position: relative;
  margin-right: 8px;
}

.employee-image {
  border: 1px solid;
  border-radius: 50%;
  margin-right: 10px;
  width: 57px;
  height: 57px;
}

.status-dot {
  position: absolute;
  bottom: 2px;
  right: -8px;
  transform: translateX(-50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid white;
}

.status-dot.annual-leave {
  background-color: green;
}

.status-dot.sick-leave {
  background-color: red;
}

.employee-name {
  color: #292a3d;
  font-family: Poppins-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90%;
  text-wrap: nowrap;
}

.employee-dates {
  color: #666;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  font-family: Poppins-Medium;
}

.employee-status {
  color: #292a3d;
  font-family: Poppins-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}

.search-container {
  position: relative;
  margin: 0px 16px 20px;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #757575;
  pointer-events: none;
}

.search-input {
  width: 100%;
  padding: 7px 7px 7px 40px;
  border: 1px solid #ccc;
  font-size: 13px;
  color: #3d3e5c;
  font-weight: 400;
  font-family: Poppins-Medium;
  border-radius: 19.738px;
  background: #fbfaff;
  line-height: 16.448px;
  letter-spacing: 0.082px;
}

.navigation-arrows {
  display: flex;
  gap: 10px;
}

.next-prev-button {
  width: 130px !important;
}
