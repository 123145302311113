:root {
  --sidebar-bg-color: #fbfaff;
  --active-bg-color: var(--color-primitives-primary-100);
  --user-message-bg-color: #ffffff;
  --chat-message-bg-color: #ffffff;
  --input-border-color: #ffffff;
  --separator-color: #ffffff;
  --scrollbar-color: #ccd0d5;
  --scrollbar-hover-color: #a4a6b3;
  background-color: #ffffff;
  font-family: Poppins;
}

.primaryBtn,
.secondaryBtn {
  border-radius: 42px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  font-family: "Poppins-Medium" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.1px !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  text-align: center !important;
  padding: 8px 24px !important;
}

.primaryBtn {
  border: 1px solid var(--temp-color-primitives-primary-800) !important;
  color: #ffffff !important;
  background-color: var(--temp-color-primitives-primary-800) !important;
}

.primaryBtn:hover {
  border: 1px solid var(--temp-color-primitives-primary-800) !important;
  color: var(--temp-color-primitives-primary-800) !important;
  background-color: transparent !important;
}

.secondaryBtn {
  border: 1px solid var(--color-primitives-primary-50) !important;
  color: var(--color-primitives-primary-600) !important;
  background-color: var(--color-primitives-primary-50) !important;
}

.secondaryBtn:hover {
  border: 1px solid var(--color-primitives-primary-600) !important;
  color: var(--color-primitives-primary-600) !important;
}

.edgeVivo {
  display: flex;
  height: calc(100vh - 46px);
  font-family: "Poppins", sans-serif;
}

.edgeVivoTitle {
  color: #292a3d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.conversationsList {
  list-style: none;
  padding: 0;
  margin: 10px;
}

.conversationItem {
  padding: 5px 15px;
  margin-block: 5px;
  cursor: pointer;
  height: 45px;
  display: flex;
  align-items: center;
}

.conversationItem.active {
  background-color: var(--active-bg-color);
  border-radius: 12px;
  font-weight: 600;
}

.conversationItem:hover {
  border-radius: 12px;
  background-color: var(--color-primitives-primary-50);
}

.deleteConversationButton {
  visibility: hidden;
}

.conversationItem:hover .deleteConversationButton {
  visibility: visible;
  padding: 3px;
}

.conversationItem:hover .deleteConversationButton:hover {
  background: white;
}

.narrowWidth {
  width: 60%;
  align-self: center;
  margin-top: 70px;
}
.narrowWidth::-webkit-scrollbar {
  display: none;
}

.conversationName,
.lastMessage,
.timestamp {
  margin: 0;
}

.lastMessage {
  color: #65676b;
  font-size: 0.85rem;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.messageText {
  word-break: break-word;
}

.lastMessage {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 140px;
}

.timestamp {
  font-size: 0.75rem;
  color: #65676b;
  text-align: right;
}

.chatInterface {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--color-primitives-primary-100);
  background-color: #ffffff;
}

.messagesArea {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
}

.messageMeta {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.messageDetails {
  display: flex;
  flex-direction: column;
}

.messageContent {
  max-width: 100%;
  white-space: pre-wrap;
}

.message {
  max-width: 100%;
  padding: 5px 10px;
  border-radius: 12px;
  position: relative;
  margin-inline: auto;
}

.userMessage {
  margin-right: auto;
  text-align: left;
}

.VivoMessage {
  margin-right: auto;
  text-align: left;
}

.senderName {
  color: #292a3d;
  font-family: "Poppins-Semibold" !important;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 32px;
}

.timestamp {
  font-size: 0.75rem;
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: #65676b;
}

.inputArea {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--separator-color);
  padding: 10px;
  background-color: #ffffff;
  width: 60%;
  align-self: center;
}

.messageInput {
  flex-grow: 1;
  border-radius: 18px;
  border: 1px solid var(--input-border-color);
  padding: 10px;
  margin-right: 10px;
}

.sendButton {
  background-color: #0078ff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
}

.sidebar:hover::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-hover-color);
}

.message.Vivo .loading-message {
  display: inline-block;
}

.loadingDot {
  height: 8px;
  width: 8px;
  margin: 0 2px;
  background-color: #333;
  border-radius: 50%;
  display: inline-block;
  animation: loadingDot 1.4s infinite ease-in-out both;
}

.loadingDot:nth-child(1) {
  animation-delay: -0.32s;
}

.loadingDot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes loadingDot {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.messagesArea {
  flex-grow: 1;
}

.avatar {
  max-width: 100%;
  height: auto;
}

@media (max-width: 480px) {
  .messageDetails {
    font-size: 14px;
  }
}

.coversations-sidebar {
  top: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  max-width: 300px;
  overflow-y: auto;
  padding: 0 10px;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 200;
  background-color: var(--sidebar-bg-color);
  overflow-y: auto;
}

.coversations-sidebar.collapsed {
  display: none;
}

.coversations-sidebar.expanded {
  transform: translateX(0);
}

.chatInterface {
  transition: margin-left 0.3s ease;
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.backdrop {
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.coversations-sidebar.expanded ~ .backdrop {
  display: block;
}

.newChatExperience {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-inline: auto;
}

.examplePrompts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  width: max-content;
  margin-top: 100px;
}

.examplePrompt {
  background: none;
  width: 200px;
  cursor: pointer;
  flex: 0 0 calc(50% - 10px);
  display: flex;
  align-items: center;
  min-height: 70px;
  box-sizing: border-box;
  text-align: center;
  overflow: visible;
  padding: 16px 19px;
  border-radius: 12px;
  border: 1px solid var(--color-primitives-primary-100);
  position: relative;
  padding-right: 30px;
}

.examplePrompt:hover {
  background-color: #fbfaff;
}

.examplePrompt:hover .promptArrow {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s ease;
}

.promptArrow {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease;
}
@media (max-width: 1140px) {
  .examplePrompt {
    flex: 0 0 100%;
    width: 100px;
  }
}

@media (max-width: 800px) {
  .examplePrompt {
    width: 70px;
  }
}
@media (max-width: 600px) {
  .narrowWidth {
    width: 100%;
    align-self: center;
    margin-top: 30px;
  }
  .inputArea {
    width: 100%;
  }
}

@media (max-width: 1440px) {
  .narrowWidth {
    width: 90%;
    align-self: center;
    margin-top: 30px;
  }
  .inputArea {
    width: 90%;
  }
}

.newUserParentDiv {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 46px);
}

.newUserExperience {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}

.typist-text-vivo {
  font-family: Inter !important;
  color: #292a3d;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.strong_text {
  font-family: Inter !important;
  font-weight: 600;
}
.vivoNewUserExperience {
  height: 132px;
  margin-bottom: 38px;
}

.newUserListItem {
  display: flex;
  padding: 12px;
  justify-content: start;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 20px;
  border-radius: 12px;
  border: 1px solid var(--color-primitives-primary-100);
  width: 400px;
}

.errorPageParentDiv {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 46px);
}

.errorPageExperience {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}

.vivoErrorPageExperience {
  height: 132px;
  margin-bottom: 38px;
}

.errorPageListItem {
  display: flex;
  padding: 12px;
  justify-content: start;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-bottom: 20px;
  border-radius: 12px;
  border: 1px solid var(--color-primitives-primary-100);
  width: 400px;
}

.logo {
  width: 100px;
  height: 100px;
}

.vivoLogo {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.categoryTitle {
  color: #52537a;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.normal-line,
.bullet-line,
.sub-bullet-line,
.text-section {
  font-family: var(--font-family-inter) !important;
}

.spaced-section {
  margin-bottom: 20px;
}
