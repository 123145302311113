.slider-container {
  position: relative;
  height: 100%;

  .slides-container {
    height: 100%;
    margin-top: 5rem;

    @media (max-width: 767px) {
      padding-top: 0rem;
      margin-top: 0px;
    }
  }

  .heading-text {
    text-align: center;

    @media (max-width: 768px) {
      max-width: 320px;
    }
  }

  .description-text {
    color: var(--color-text-body-primary);
    text-align: center;
    max-width: 450px;
    margin-bottom: 60px;
    @media (max-width: 768px) {
      margin-bottom: 21px;
    }
  }

  .three-dots-container {
    position: absolute;
    bottom: 66px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    display: flex;
    gap: 8px;

    @media (max-width: 768px) {
      margin-top: 21px;
      bottom: 0px;
    }

    .dot-color-inactive {
      background-color: var(--color-icon-information-inverse);
    }
    .dot-color-active {
      background-color: var(--color-icon-primary);
    }

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 0;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .arrow-btns {
      text-align: center;
    }
  }
  .slider-images {
    width: 100%;
    max-width: 546px;
    height: 348px;

    @media (max-width: 767px) {
      width: 272px !important;
      height: 174px !important;
    }
  }
}
