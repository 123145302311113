.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 1000;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 254, 254, 0.788);

  background: -webkit-radial-gradient(rgba(255, 255, 255, 0.788));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1200ms infinite linear;
  -moz-animation: spinner 1200ms infinite linear;
  -ms-animation: spinner 1200ms infinite linear;
  -o-animation: spinner 1200ms infinite linear;
  animation: spinner 1200ms infinite linear;
  border-radius: 0.3em;
  -webkit-box-shadow:
    var(--color-primitives-primary-600) 1.5em 0 0 0,
    var(--color-primitives-primary-600) 1.1em 1.1em 0 0,
    var(--color-primitives-primary-600) 0 1.5em 0 0,
    var(--color-primitives-primary-600) -1.1em 1.1em 0 0,
    var(--color-primitives-primary-600) -1.5em 0 0 0,
    var(--color-primitives-primary-600) -1.1em -1.1em 0 0,
    var(--color-primitives-primary-600) 0 -1.5em 0 0,
    var(--color-primitives-primary-600) 1.1em -1.1em 0 0;
  box-shadow:
    var(--color-primitives-primary-600) 1.5em 0 0 0,
    var(--color-primitives-primary-600) 1.1em 1.1em 0 0,
    var(--color-primitives-primary-600) 0 1.5em 0 0,
    var(--color-primitives-primary-600) -1.1em 1.1em 0 0,
    var(--color-primitives-primary-600) -1.5em 0 0 0,
    var(--color-primitives-primary-600) -1.1em -1.1em 0 0,
    var(--color-primitives-primary-600) 0 -1.5em 0 0,
    var(--color-primitives-primary-600) 1.1em -1.1em 0 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.MuiChip-filled {
  height: 28px !important;
}

.MuiChip-outlined {
  height: 28px !important;
}
/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.targetLastChild:last-child {
  border-right: none !important;
}
