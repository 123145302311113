.payment-method-heading {
  color: var(--Neutral-10, #14151f);
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
}

.payment-methods-heading {
  color: #52537a;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.credit-card-label {
  color: var(--Neutral-10, #14151f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.credit-card-container {
  display: flex;
  gap: 0.5rem;
  padding: 16px 24px;
  margin-top: 1rem;
  border-radius: 12px;
  border: 1px solid #b2b4e2;
}

.ach-recommendation-benefits-label {
  color: var(--Neutral-10, #14151f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.payment-method-desc {
  color: #292a3d;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.payment-illustration {
  width: 250px;
  height: 250px;
}

.connect-plaid-btn {
  color: var(--color-primitives-primary-600);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
}

.add-payment-info-img {
  position: absolute;
  top: 85px;
  right: 65px;
  padding: 0px;
  background: none;
  border: none;
  border-radius: 6px;
}

.payment-info-img {
  position: absolute;
  top: 14px;
  right: 20px;
  padding: 2px;
  border-radius: 6px;
  background: none;
  border: none;
}

.payment-info-img:hover,
.add-payment-info-img:hover {
  background: var(--color-primitives-primary-50);
  transition: 0.3s ease;
}

.payment-popover-heading {
  color: #292a3d;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
}

.popover-container {
  padding: 12px;
  width: 225px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
}

.payment-popover-desc {
  color: #808080;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
}

.payment-contact-email {
  color: var(--color-primitives-primary-600);
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
}

.payment-contact-email-wrapper {
  border-radius: 6px;
  background: var(--color-primitives-primary-50);
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
}

.plaid-link-closed-container {
  border-radius: 12px;
  background: var(--color-primitives-primary-50);
  padding: 16px 24px;
}

.plaid-link-closed-wrapper {
  width: 1077px;
  margin: auto;
}

.plaid-link-close-desc {
  color: #292a3d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.plaid-link-close-btn {
  color: var(--color-primitives-primary-600);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.ach-recommendation-container {
  border-radius: 12px;
  background: #fbf0dd;
  padding: 16px 24px;
  margin-top: 24px;
}

.ach-recommendation-heading {
  color: #292a3d;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.ach-recommendation-desc {
  color: #292a3d;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-top: 12px;
}

.ach-recommendation-benefits-label {
  color: var(--Neutral-10, #14151f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.payment-method-ach-label {
  color: var(--Neutral-10, #14151f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.popover-wrapper .MuiPopover-paper {
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16) !important;
}

.payment-details-container {
  padding: 16px 24px;
  display: block;
  border-radius: 12px;
  border: 1px solid #b2b4e2;
  width: 400px;
  margin-top: 16px;
}

.payment-method-edit {
  color: var(--color-primitives-primary-600);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.ach-detail-label {
  color: #52537a;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.ach-detail-value {
  color: #14151f;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.last-payroll-month {
  color: #52537a;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.last-payroll-amount {
  color: #14151f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.payment-method-status {
  display: flex;
  gap: 4px;
  color: #477e35;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  padding: 2px 8px;
}

.chevron-right-payment {
  align-self: center;
}

.payment-method-status-container {
  border-radius: 6px;
  background: #ebfae6;
  height: max-content;
  align-self: flex-end;
}

.payment-method-status {
  display: flex;
  padding: 2px 4px;
  gap: 4px;
}
