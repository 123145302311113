.payroll-details-container {
  width: 894px;
  border-radius: 10px;
  margin: auto;
  box-shadow: 0px 1px 14px 0px rgba(2, 0, 110, 0.12);
  background: white;
  overflow: scroll;
  overflow-y: hidden;
}

.payroll-details-header {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(
    92deg,
    var(--temp-color-primitives-primary-800) 17.22%,
    var(--color-primitives-primary-900) 84%
  );
  color: white;
  padding: 1.1rem 4.7rem;
  border-radius: 12px 12px 0 0;
  background-repeat: no-repeat !important;
}

.pd-header-left-chip {
  background-color: #fcfcff !important;
}

.pd-payment-overview {
  padding: 1rem 4.7rem 2rem 4.7rem;
}

.amount-fee-item-icon {
  width: 14px !important;
  height: 14px !important;
  margin-left: 4px;
  color: var(--color-text-body-primary);
}
