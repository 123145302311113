@import "styles/Typography.scss";
@import "styles/Colors.scss";

.onboarding-payment-loader-modal {
  .lottie-animation-container {
    margin-bottom: -40px;
    margin-top: -80px;
  }

  display: inline-flex;
  height: 226px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: var(--border-radius-md);
  background: var(--color-surface-primary);
  padding: 16px;
}

.payment-lottie-animation {
  width: 150px !important;
  height: 150px !important;

  @media (max-width: 768px) {
    svg {
      width: 100% !important;
      height: 180% !important;
    }
  }
}

.onboarding-payment-loader-content {
  display: flex;
  width: 100%;
  max-width: 586px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;

  @media (max-width: 768px) {
    width: 320px;
    height: 226px;
    padding: 0px 16px;
    gap: 16px;
    flex-shrink: 0;
  }

  .onboaring-payment-loader-text {
    @extend .para-body-m-regular;
    @extend .color-text-body-primary;
    text-align: center;
    width: 100%;
  }
}
