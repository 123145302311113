.calendly-meeting-main-container {
  height: 100%;

  .calendly-onboarding-bottom-button {
    border-top: 1px solid var(--color-border-primary);
    padding: 12px;
    display: flex;
    width: -webkit-fill-available;
    justify-content: flex-end;
    position: fixed;
    bottom: 0px;
    background: var(--color-surface-primary);
    z-index: 2;
  }

  .calendly-onboarding-bottom-button-container {
    padding: 0 16px;
  }

  @media (max-width: 768px) {
    .calendly-onboarding-bottom-button {
      width: 100%;
    }
  }
}

.calendly-onboarding-content-container {
  height: 100%;
}
