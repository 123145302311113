.holidaycard {
  background-color: #ffffff;
  border-radius: 24px;
  width: 348px;
}

.holidaycard-image {
  width: 100%;
  height: 112px;
  object-fit: cover;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.holidaycard-content {
  display: flex;
  flex-direction: column !important;
  padding: 20px 15px 15px;
}

.holidaycard-title {
  font-family: "Poppins-Medium" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #14151f;
}

.holidaycard-date {
  font-family: "Poppins-Medium" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: var(--color-primitives-primary-600);
}

.holidaycard-button {
  padding: 5px 10px !important;
  border: 1px solid #777680 !important;
  border-radius: 22px !important;
  background-color: transparent !important;
  color: #3d3e5c !important;
  font-family: "Poppins-Regular" !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 16px !important;
  cursor: default !important;
}

.holidaycard-description {
  font-family: "Poppins-Regular" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  color: #52537a !important;
  margin-top: 32px !important;
}
