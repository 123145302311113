.request-timeoff-container {
  width: 62.5rem;
  margin: 0 auto;
}

.request-timeoff-type-wrapper {
  margin: 0 0 1rem 0;
  display: flex;
  justify-content: space-between;
}

.request-timeoff-type-label {
  color: #000;
  font-size: 14px;
  font-family: "Poppins-Medium";
  padding-bottom: 0.5rem;
}

.request-timeoff-type-value {
  color: #000;
  width: 9rem;
  padding: 4px 1rem;
  font-size: 14px;
  background: #ffc200;
  font-family: Poppins-Regular;
  border-radius: 29px;
  display: flex;
  justify-content: center;
}

.request-timeoff-type-chip {
  color: #000;
  font-size: 11px !important;
  background: var(--color-primitives-primary-50) !important;
  font-family: Poppins-Regular;
  margin-left: 1rem;
  align-self: end;
}

.request-timeoff-wrapper {
  gap: 2rem;
  display: flex;
}

.date-range-wrapper {
  border: 1px solid #e0e2ff38;
  border-radius: 12px;
  background: #fff;
  box-shadow:
    0px 1px 6px 0px rgba(2, 0, 110, 0.11),
    0px 1px 1px 0px rgba(2, 0, 110, 0.22);
  width: 664px;
}

.request-timeoff-rules {
  border: 1px solid var(--color-primitives-primary-100);
  border-radius: 12px;
  height: max-content;
  width: 337px;
}

.request-timeoff-rules-heading {
  color: #000;
  padding-inline: 18px;
  padding-top: 14px;
  padding-bottom: 11px;
  font-size: 14px;
  font-family: Poppins;
  line-height: normal;
  font-weight: 600;
}

.request-timeoff-rules-subheading {
  color: #000;
  padding-inline: 18px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 13px;
  font-family: Poppins-Medium;
}

.request-timeoff-rules-para {
  color: #000;
  padding-inline: 18px;
  padding-bottom: 10px;
  padding-top: 5px;
  font-size: 13px;
  font-family: Poppins-Regular;
}

.request-timeoff-action-btns {
  gap: 1rem;
  display: flex;
  margin-bottom: 1.5rem;
}

.request-timeoff-today-box-indicator {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  border: 1px solid var(--color-primitives-primary-900);
  align-self: center;
}

.request-timeoff-selected-box-indicator {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background: var(--temp-color-primitives-primary-800);
  align-self: center;
}

.request-timeoff-indicators-container {
  display: flex;
  gap: 1.1rem;
  padding-bottom: 0.6rem;
}

.request-timeoff-indicator-box-label {
  display: flex;
  gap: 0.4rem;
}

.timeoff-selected {
  margin-top: 12px;
}

.deductions-chip {
  background: #ffc6ca !important;
  color: #97000c !important;
  font-family: Poppins-Regular !important;
}

.request-timeoff-textarea {
  margin: 1rem;
  padding: 0.5rem;
  width: -webkit-fill-available;
  border: 1px solid var(--color-primitives-primary-100);
  border-radius: 12px;
  background: #fbfaff;
  border: 1px solid var(--color-primitives-primary-100);
  font-size: 14px !important;
  line-height: 19.5px !important;
  font-weight: 500;
}

.request-timeoff-files {
  background: #fbfaff;
  border: 1px dashed var(--color-primitives-primary-400);
}

.notice-period-chip {
  color: #007b23;
  font-size: 11px !important;
  background: rgba(185, 239, 216, 1);
  font-family: Poppins-Regular;
}

.notice-period-chip-label div {
  align-self: center;
  font-size: 12px;
}

.frequent-leave-chip {
  color: #686868;
  font-size: 11px !important;
  background: #cecece !important;
  font-family: Poppins-Regular;
}

.step-2-dates {
  display: flex;
  justify-content: space-between;
}

.edit-button {
  border-radius: 42px !important;
  border: 1px solid var(--color-primitives-primary-100) !important;
  height: 29px !important;
  padding: 12px !important;
  color: var(--color-primitives-primary-600) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important ;
  font-weight: 500 !important;
  line-height: 20px !important;
  /* 142.857% */
  letter-spacing: 0.1px !important;
}

.selected-timeoff-text {
  font-family: Poppins-Regular;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #7c7eb6;
}

.req-total-no-of-days {
  font-family: Poppins-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: var(--color-primitives-primary-600);
}

.req-leave-date-from {
  font-family: Poppins-Medium;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.req-timeoff-reason {
  font-family: Poppins-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  /* background: linear-gradient(0deg, #292A3D, #292A3D), */
  /* linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)); */
}

.time-off-rules-list ul {
  list-style-type: disc !important;
  padding-left: 1rem;
}

.time-off-rules-list ul li {
  font-family: Poppins;
}

.annual-accordian {
  width: 666px;
  height: 60px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #ba1a1a;
  background-color: #ffedea;
  border-radius: 12px;
  padding: 8px 12px;
}
