.error-indicator-list-container {
  margin: 18px 0;

  .error-list-icon {
    height: 16px !important;
    width: 16px !important;
  }

  .icon-color-initial {
    color: var(--color-icon-secondary);
  }
  .icon-color-success {
    color: var(--color-icon-success);
  }

  .icon-color-danger {
    color: var(--color-icon-danger);
  }

  .text-color-initial {
    color: var(--color-text-body-primary);
  }

  .text-color-success {
    color: var(--color-text-success);
  }

  .text-color-error {
    color: var(--color-text-error);
  }
}
