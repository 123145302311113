.manage-timeoff-wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
}

.timeoff-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 1rem;
  gap: 18px;
}

.timeoff-summary-container {
  width: 70%;
  border-radius: 12px;
  border: 1px solid var(--color-primitives-primary-100);
}

.dropdowns-section {
  display: flex;
  padding: 1rem;
  gap: 5px;
  flex-flow: wrap;
}

.dropdowns {
  background-color: #fbfaff;
  border-radius: 33px;
  border: none;
  display: flex;
  gap: 45px;
  justify-content: space-between;
  padding: 0px 20px;
  width: max-content;
}

.dropdowns-company {
  background-color: #fbfaff;
  border-radius: 33px;
  border: none;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  width: 100%;
}

.dropdown-text {
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins-Medium;
  color: #292a3d !important;
}
.timeoff-flags-container {
  width: 30%;
  border-radius: 12px;
  border: 1px solid var(--color-primitives-primary-100);
  background-color: #fbfaff;
}

@media (max-width: 1125px) {
  .timeoff-container {
    flex-direction: column;
  }
  .timeoff-flags-container {
    width: 100%;
  }
  .timeoff-summary-container {
    width: 100%;
  }
}

.timeoff-used-heading-activity {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-primitives-primary-100);
  padding: 8px 14px;
  height: 40px;
  align-items: center;
}

.timeoff-used-heading {
  font-family: Poppins-Medium;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-style: normal;
  font-weight: 600;
}

.leaves-breakdown-section {
  display: flex;
  border-top: 1px solid var(--color-primitives-primary-100);
}

.all-total-leaves-section {
  width: 40%;
  border-right: 1px solid var(--color-primitives-primary-100);
  padding: 1rem 1.5rem;
}

.leaves-graph {
  width: 60%;
  padding-top: 40px;
  height: 177px;
  position: relative;
}

.leave-graph-title {
  color: var(--Neutral-30, #3d3e5c);
  font-family: Poppins-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  position: absolute;
  left: 21px;
  top: 10px;
  text-transform: capitalize;
}

.total-leaves-count {
  font-size: 28px;
  font-weight: 600;
  font-family: Poppins-Medium;
  color: #292a3d !important;
  margin: 0 !important;
  padding-top: 10px;
}

.scheduled-leaves-count {
  font-size: 13px;
  font-weight: 500;
  font-family: Poppins-Medium;
  color: #0a9f72 !important;
  margin: 0 !important;
  padding-bottom: 20px;
}

.leaves-separate-count {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.scheduled-leaves-and-holidays-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  gap: 27px;
}

.calender-section {
  width: 407px;
  border: 1px solid var(--color-primitives-primary-100);
  border-radius: 12px;
  overflow: hidden;
  height: auto;
}

.people-out-on-day {
  width: 75%;
  border: 1px solid var(--color-primitives-primary-100);
  border-radius: 12px;
}

.report-button {
  width: 172.64px;
  height: 39.75px;
  padding: 7px 22px;
  gap: 13px;
  border-radius: 47px;
  border: 2.27px solid var(--color-primitives-primary-600);
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.1px;
  text-align: center;
  color: var(--color-primitives-primary-600);
  cursor: pointer;
}

@media (max-width: 1125px) {
  .scheduled-leaves-and-holidays-container {
    flex-direction: column;
  }

  .calender-section {
    width: 100%;
  }

  .people-out-on-day {
    width: 100%;
  }
}
