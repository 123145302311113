@import "styles/Typography.scss";
.calendly-success-modal-container {
  height: 756px !important;
  width: 1200px;
  max-width: 1200px !important;
  @media (max-width: 768px) {
    width: 90% !important;
    height: 70% !important;
  }
}

.calendly-success-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  position: relative;
  height: 100%;
  overflow: hidden;

  .background-gif {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("https://s3-alpha-sig.figma.com/img/f6a6/4233/5757a28ac5e104268607c25845c1eecd?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=g5cJn2J3QZ0aJmTuL8weXR2QT5xOcYgnwgK4-8pesfrV-vwzDvBJ1jlAEyQlw3bN8GIPkFI7ARK75QOBXbH4KEAbV03OBUAwkIAHfrAGbPuy83TC-aK83MvnFJYbc5ScVKFUK0OULKTuIkXPIJ0KDlbSRWSLsbVZHvi74Z0JQDZBwGkftJn8eDes0pIjJngGNlh30ERu5qlp9P-lq4FJ1ittAUUiwn8MDypWj5IzC7zZ~NMGe90czpAw~IGR7jURYOS16Bs-TZWfrNm38FlZRmvN8sUg~CCIFmoRy-80orsd7B8-WTL8wsKjeacUO8a8a7hh-s5XA~A3ck-5esl~Fg__");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.8;
    pointer-events: none;
    z-index: 0;
  }

  .lottie-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;

    > div {
      width: 100%;
      height: 100%;
    }
  }

  .modal-content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .all-set-image {
      width: 200px;
      height: 200px;
    }

    .heading-color {
      margin-top: 20px;
      color: var(--color-text-heading);
    }

    .description-color {
      margin-top: 8px;
      color: var(--color-text-body-primary);
    }

    .homepage-button {
      margin-top: 12px;
      min-width: 200px;
    }
  }
}

.mobile-warning-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-surface-primary) !important;
  z-index: 9999;
  display: flex;
  align-items: start;
  justify-content: center;
  padding-inline: 20px;
  padding-top: 32px;
  overflow: auto;

  .mobile-warning-modal {
    max-width: 500px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    justify-content: start;

    .laptop-icon {
      color: var(--color-icon-secondary) !important;
      margin-bottom: 12px;
      margin-top: 104px;
    }

    p {
      @extend .para-body-m-regular;
      color: var(--color-text-body-primary);
    }
    .color-class {
      color: var(--color-text-highlight);
    }
  }
}
