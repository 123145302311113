.link-expired-main-container {
  padding-top: 116px;
  padding-bottom: 40px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .edge-logo{
    height: 47px;
    width: 91px;
    object-fit: cover;
  }

  .expiry-text-container {
    max-width: 532px;

    .description-text {
      color: var(--color-text-body-primary);
    }
  }

  .error-icon {
    color: var(--color-surface-action-warning);
    padding-bottom: 20px;
    height: 54px;
    width: 54px;
  }

  .mail-to-link {
    color: var(--color-text-action);
  }

  .text-color {
    color: var(--color-text-body-primary);
  }
}
