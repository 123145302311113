@import "styles/Typography.scss";

.no-result-container {
  display: flex;
  height: 100px;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.no-result-container p {
  @extend .heading-h5-regular;
  color: var(--color-text-headings-primary);
  text-align: center;
}
