.customer-onboarding-need-help-section {
  padding: 12px;
  align-items: center;
  background: var(--color-surface-primary-hover);
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: var(--color-brand-primary-grey-100);
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 16px;
    margin-top: 15px;
  }
}

.need-help-text-container {
  h3 {
    margin: 0px;
  }
}

.need-help-email-icon-wrapper {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--border-radius-full);
  background: var(--color-surface-primary);
  box-shadow: 0px 1px 2px 0px var(--color-elevation-default);
  svg {
    color: var(--color-brand-primary-grey-400);
  }
}
