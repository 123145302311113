@import "styles/Typography.scss";

.sidebar-icon {
  color: var(--color-primitives-white-white);
  width: 20px;
  height: 21px;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-primitives-primary-700);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-primitives-primary-700);
  }
}

.sidebar-header {
  height: 56px;
  min-height: 56px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar-logo {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  div {
    display: grid;
  }
}

.sidebar-user-info {
  width: 100%;
  text-align: center;
}

.sidebar-user-info-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;
  padding-top: 24px;
}

.sidebar-user-info-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.sidebar-user-avatar {
  margin-right: 8px;

  .img,
  .MuiAvatar-root {
    width: 32px;
    height: 32px;
  }
}

.sidebar-user-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.sidebar-company-name {
  @extend .para-body-m-regular;

  color: var(--color-primitives-white-white) !important;
  margin-bottom: 2px !important;
}

.sidebar-start-date {
  @extend .para-body-s-regular;

  color: var(--color-text-body-tertiary) !important;
  opacity: 0.4 !important;
}

.sidebar-menu-container {
  flex-grow: 1;
  padding-top: 4px;
  padding-right: 12px;
}

.sidebar-menu-list {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.sidebar-menu-item {
  border-radius: 4px;
  overflow: hidden;
  padding: 0 8px !important;
  height: 32px;

  &:hover {
    background-color: var(--color-surface-sidebar-menu-hover);
  }
}

.sidebar-menu-category-title {
  margin-top: 12px;
  margin-bottom: 2px;
  color: var(--color-text-body-tertiary) !important;
  height: 20px;
  padding-left: 8px;
}

.sidebar-menu-item-selected {
  background-color: var(--color-surface-sidebar-menu-hover) !important;
}

.sidebar-menu-item-button {
  padding: 0 !important;
}

.sidebar-menu-item-icon {
  min-width: unset !important;
  padding-right: 8px;
  color: var(--color-icon-sidebar) !important;

  svg {
    height: 20px;
    width: 20px;
  }
}

.sidebar-menu-item-text {
  span {
    @extend .sidebar-menu-regular;

    color: var(--color-primitives-white-white);
  }
}

.sidebar-footer {
  margin-top: auto;
  padding-right: 12px;
}

.sidebar-sticky-menu-list {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.sidebar-footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
}

.sidebar-footer-logo {
  margin-bottom: 8px;
}

.sidebar-collapsed-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-collapsed-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  flex-grow: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-primitives-primary-700);
    border-radius: 4px;
  }
}

.sidebar-collapsed-logo {
  margin-bottom: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}

.sidebar-collapsed-menu-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-collapsed-category {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-collapsed-divider {
  width: 32px;
  height: 1px;
  background-color: var(--color-border-tertiary);
  margin: 12px auto;
}

.sidebar-collapsed-menu-item {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
  cursor: pointer;
  border-radius: var(--border-radius-sm);
  color: var(--color-icon-sidebar) !important;

  &:hover {
    background-color: var(--color-surface-sidebar-menu-hover);
  }
}

.sidebar-collapsed-menu-item svg {
  width: 20px;
  height: 20px;
}

.sidebar-collapsed-menu-item-selected {
  background-color: var(--color-surface-sidebar-menu-hover) !important;
}

.sidebar-collapsed-footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
}

.sidebar-collapsed-sticky-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

.sidebar-collapsed-user-avatar {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  .img,
  .MuiAvatar-root {
    width: 32px;
    height: 32px;
  }
}

.sidebar-collapsed-footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-collapsed-footer-logo {
  margin-bottom: 8px;
}

.sidebar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}

.sidebar-toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--color-surface-sidebar-menu-hover);
  transition: all 0.2s ease-in-out;
  width: 24px;
  height: 24px;

  &.expanded {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    svg {
      width: 20px;
      height: 20px;
      color: var(--color-icon-sidebar);
    }
  }

  &.collapsed {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin: 0;
    padding: 0;

    svg {
      width: 20px;
      height: 20px;
      color: var(--color-icon-sidebar);
    }
  }

  &:hover {
    background-color: var(--color-surface-sidebar-menu-hover);
  }
}
