.ep-dashboard {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  background-color: #f5f5ff;
  /* height: calc(100vh - 46px); */
}

.ep-dashboard-left {
  flex: 1;
  background-color: #fbfaff;
}

.ep-payroll-h1 {
  display: flex;
  justify-content: space-between;
  width: 752px;
  margin: 20px auto;
}

.ep-payroll-h1a {
  font-family: Poppins-Medium !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.ep-payroll-h1b {
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  color: var(--color-primitives-primary-500);
  cursor: pointer;
}

.ep-payroll-data-div {
  display: flex;
  flex-direction: column;
  width: 752px;
}

.ep-dashboard-right {
  display: none;
}

.ep-dash-holidays-bottom {
  background: #ffffff;
  box-shadow: -10px 1px 34px rgba(2, 0, 110, 0.06);
  border-radius: 13px;
  width: 90%;
  margin: 5px auto;
}

.ep-dash-holidays-this {
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 10px;
}

.ep-dash-holidays-main {
  display: flex;
  gap: 5px;
  padding: 10px;
}

.ep-dash-holidays-main-title {
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #14151f;
}

.ep-dash-holidays-main-others {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #14151f;
}

.ep-dash-holidays-main-date {
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--color-primitives-primary-600);
}

.ep-dash-holidays-view {
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--color-primitives-primary-600);
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.hr-poc-wrapper .ep-hr-poc-card {
  margin: auto;
}

@media only screen and (min-width: 900px) {
  .ep-payrol-content {
    display: flex;
    justify-content: space-between;
  }

  .ep-payroll-data-div {
    flex-direction: row;
    gap: 16px;
    margin: 0px auto;
  }

  .ep-dashboard-right {
    display: flex;
    flex-direction: column;
    width: 327px;
  }

  .ep-dash-annouce {
    margin: 16px 13px 0px 13px;
  }

  .ep-dash-holidays {
    margin: 50px 10px 10px 10px;
  }
}
