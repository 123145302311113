.banner-box {
  width: 100%;
  background-color: #fbf0dd;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 46px;
  position: sticky !important;
  height: 48px;
}

.missing-payment-method-text {
  color: #292a3d;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.missing-payment-method-text-blue {
  font-weight: 600 !important;
  color: var(--color-primitives-primary-600) !important;
}
