@import "styles/Typography.scss";
@import "styles/Colors.scss";

.mail-icon-container {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 41px;
  background: var(--color-surface-primary-hover);

  svg {
    width: 28px;
    height: 28px;
    color: var(--color-icon-secondary);
  }
}

.onboarding-get-help-dialogue-main-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.get-help-modal-max-words {
  @extend .para-body-s-regular;
  @extend .color-text-heading-primary;
  opacity: 0.6;
  margin-top: -16px;
}
