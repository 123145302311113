.history-pending-leaves-card-container {
  border-radius: 12px;
  background: #fff;
  width: 100%;
  border: 1px solid var(--color-primitives-primary-100);
}

.history-description {
  text-align: left;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  max-width: 370px;
  overflow: hidden;
  min-height: 20px;
}
