.summary-card {
  background-color: #ffffff;
  /* padding: 8px; */
  border-radius: 5px;
  margin-block: 20px;
}

.greetings-text h2 {
  margin: 0;
  color: #292a3d;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}

.greetings-text p {
  margin: 0;
  color: #14151f;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.summary-card-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summary-card-top-section svg {
  margin-right: 16px;
}

.summary-card-top-section p {
  color: #2b2b5f;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 8px;
  padding-bottom: 8px;
}

.active-customers-employees-count {
  color: #2b2b5f;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding-left: 8px;
  margin-bottom: 2px;
}

.active-customers-employees-label {
  color: #696996;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.active-customers-employees {
  padding: 8px;
  margin-top: 8px;
  margin-left: 8px;
  padding-bottom: 0;
  padding-left: 0;
}

.summary-grid {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.summary-item {
  text-align: left;
  position: relative;
  padding: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
}

.summary-item:hover,
.active-customers-employees:hover {
  background-color: #f2f2f8;
  border-radius: 2px;
  cursor: pointer;
}

.summary-item h6 {
  color: #2b2b5f;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-bottom: 2px;
}

.summary-item p {
  color: #696996;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.countIcon {
  padding: 8px;
}

.separator {
  width: 1px;
  background-color: var(--color-primitives-primary-50);
  height: 31px;
  margin: 0 12px;
}

.view-customer-grid {
  margin-top: inherit !important;
}

.view-customer-grid div {
  cursor: pointer;
  margin-top: 0;
}

.view-customer-grid div div p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}
