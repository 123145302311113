.history-leaves-card-container {
  border-radius: 12px;
  background: #fefdff;
  border: 1px solid var(--color-primitives-primary-100);
  width: 757px;
}

@media (max-width: 880px) {
  .history-leaves-card-container {
    width: auto;
    min-width: 400px;
    max-width: 100%;
  }
}

.history-leave-reason {
  color: var(--color-primitives-primary-600);
  font-family: Poppins-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.3rem;
}

.history-leave-date {
  color: #292a3d;
  font-family: Poppins-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.history-leave-arrow {
  transform: rotate(180deg);
}

.history-leaves-date {
  display: flex;
  /* padding: 0.7rem 0; */
}

.history-leaves-time-icon {
  padding: 0.5rem 1rem;
}

.history-leaves-top-section {
  display: flex;
  justify-content: space-between;
}

.history-leave-chip {
  align-self: center;
  margin-right: 1rem !important;
}

.history-timeoff-details {
  display: flex;
  margin-left: 1rem;
}

.history-timeoff-details-text {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 12px;
  width: 25%;
  margin: 0.5rem 0;
}

.history-leave-chip-status {
  background: #ffefd3 !important;
  border: 1px solid #ffdf9c !important;
  color: #3f2e00 !important;
}

.history-leave-chip-status-icon {
  transform: scale(0.6);
  filter: invert(13%) sepia(88%) saturate(1844%) hue-rotate(41deg) brightness(100%) contrast(106%);
}
/* 
.history-timeoff-details-wrapper {
  margin-bottom: 0.8rem;
} */

.history-timeoff-comments {
  color: #292a3d;
  font-family: Poppins-Medium;
  font-size: 14px;
  padding: 0.6rem 1rem;
  margin-right: 2rem;
}

.history-timeoff-comments-img {
  clip-path: circle();
  width: 4rem;
  padding: 1rem;
}

.history-timeoff-comment-wrapper {
  border: 1px solid #e4e1ec;
  border-radius: 12px;
  margin-right: 0.5rem;
  width: 70%;
}

.history-timeoff-comment {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 14px;
  padding: 0.6rem 1rem;
}

.history-timeoff-comment-author {
  color: #c7c5d0;
  font-family: Poppins-Regular;
  font-size: 11px;
  padding-left: 1rem;
}

.history-timeoff-comments-container {
  display: flex;
}

.history-timeoff-request-date {
  color: #7f7e82;
  font-family: Poppins-Regular;
  font-size: 12px;
}

.medical-docs-btn-label {
  text-wrap: nowrap;
}
