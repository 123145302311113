.banner-conatiner {
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: #ffffff;
  width: 752px;
  margin: 16px auto;
}

.banner-img-container {
  padding: 0px 0px 0px 20px;
}

.banner-finance-img {
  margin: 6px 22px 6px 18px;
}

.banner-text-container {
  display: flex;
  padding: 0px 20px 0px 0px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.banner-text-typography {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.banner-text-btn {
  border: 1px solid #fafaff;
  border-radius: 22px;
  background-color: transparent;
  height: 32px;
  margin: 5px;
  white-space: nowrap;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.banner-text-btn-rebrand {
  background: #fafaff;
  color: #000000 !important;
}

.banner-img {
  margin-left: 2rem;
  width: 80px;
}

.banner-details {
  margin-left: 1.25rem;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.banner-btns {
  display: flex;
  margin-top: 1.5rem;
}

.banner-btns div {
  padding: 0.75rem 1.25rem;
}

.banner-btns div:hover {
  cursor: pointer;
}

.download-payslip-btn {
  background: #ffffff;
  border-radius: 42px;
  color: #ffffff;
}

.view-payslip-btn {
  margin-left: 1rem;
}
