@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1;
}

.grid-center {
  display: grid;
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: start;
}

/* Width Utilities */
.w-1 {
  width: 1rem;
}

.w-125 {
  width: 1.25rem;
}

.w-15 {
  width: 1.5rem;
}

.w-175 {
  width: 1.75rem;
}

.w-2 {
  width: 2rem;
}

.w-3 {
  width: 3rem;
}

.w-35 {
  width: 3.5rem;
}

.w-4 {
  width: 4rem;
}

.w-5 {
  width: 5rem;
}

.w-6 {
  width: 6rem;
}

.w-7 {
  width: 7rem;
}

.w-8 {
  width: 8rem;
}

.w-9 {
  width: 9rem;
}

.w-10 {
  width: 10rem;
}

/* Width Percentage Utilities */
.w-10p {
  width: 10%;
}

.w-15p {
  width: 15%;
}

.w-20p {
  width: 20%;
}

.w-25p {
  width: 25%;
}

.w-30p {
  width: 30%;
}

.w-35p {
  width: 35%;
}

.w-40p {
  width: 40%;
}

.w-45p {
  width: 45%;
}

.w-50p {
  width: 50%;
}

.w-55p {
  width: 55%;
}

.w-60p {
  width: 60%;
}

.w-65p {
  width: 65%;
}

.w-70p {
  width: 70%;
}

.w-75p {
  width: 75%;
}

.w-80p {
  width: 80%;
}

.w-85p {
  width: 85%;
}

.w-90p {
  width: 90%;
}

.w-95p {
  width: 95%;
}

.w-100p {
  width: 100%;
}

/* Height Utilities */
.h-max-con {
  height: max-content;
}

.h-1 {
  height: 1rem;
}

.h-125 {
  height: 1.25rem;
}

.h-15 {
  height: 1.5rem;
}

.h-175 {
  height: 1.75rem;
}

.h-2 {
  height: 2rem;
}

.h-3 {
  height: 3rem;
}

.h-35 {
  height: 3.5rem;
}

.h-4 {
  height: 4rem;
}

.h-5 {
  height: 5rem;
}

.h-6 {
  height: 6rem;
}

.h-7 {
  height: 7rem;
}

.h-8 {
  height: 8rem;
}

.h-9 {
  height: 9rem;
}

.h-10 {
  height: 10rem;
}

/* Padding Utilities */

.p-0 {
  padding: 0px;
}

.p-025 {
  padding: 0.25rem;
}

.p-05 {
  padding: 0.5rem;
}

.p-075 {
  padding: 0.75rem;
}

.p-1 {
  padding: 1rem;
}

.p-125 {
  padding: 1.25rem;
}

.p-15 {
  padding: 1.5rem;
}

.p-175 {
  padding: 1.75rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.p-4 {
  padding: 4rem;
}

.p-5 {
  padding: 5rem;
}

.p-6 {
  padding: 6rem;
}

.p-7 {
  padding: 7rem;
}

.p-8 {
  padding: 8rem;
}

.p-9 {
  padding: 9rem;
}

.p-10 {
  padding: 10rem;
}

.p-10p {
  padding: 10px;
}

.pt-20p {
  padding-top: 20px;
}

/* Padding Horizontal Utilities */

.px-0 {
  padding-inline: 0;
}

.px-025 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-05 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-075 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-125 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-15 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-175 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-10p {
  padding-left: 10px;
  padding-right: 10px;
}

/* Vertical Padding Utilities */

.py-025 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-05 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-075 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-1-admin {
  padding-top: 9px;
  padding-bottom: 9px;
}

.py-125 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-15 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-175 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-10p {
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Padding Top Utilities */
.pt-025 {
  padding-top: 0.25rem;
}

.pt-05 {
  padding-top: 0.5rem;
}

.pt-075 {
  padding-top: 0.75rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-125 {
  padding-top: 1.25rem;
}

.pt-15 {
  padding-top: 1.5rem;
}

.pt-175 {
  padding-top: 1.75rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-4 {
  padding-top: 4rem;
}

.pt-5 {
  padding-top: 5rem;
}

.pt-10p {
  padding-top: 10px;
}

/* Padding Left Utilities */
.pl-025 {
  padding-left: 0.25rem;
}

.pl-05 {
  padding-left: 0.5rem;
}

.pl-075 {
  padding-left: 0.75rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-125 {
  padding-left: 1.25rem;
}

.pl-15 {
  padding-left: 1.5rem;
}

.pl-175 {
  padding-left: 1.75rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pl-4 {
  padding-left: 4rem;
}

.pl-5 {
  padding-left: 5rem;
}

.pl-10p {
  padding-left: 10px;
}

/* Padding Bottom Utilities */
.pb-025 {
  padding-bottom: 0.25rem;
}

.pb-05 {
  padding-bottom: 0.5rem;
}

.pb-075 {
  padding-bottom: 0.75rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-125 {
  padding-bottom: 1.25rem;
}

.pb-15 {
  padding-bottom: 1.5rem;
}

.pb-175 {
  padding-bottom: 1.75rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pb-10p {
  padding-bottom: 10px;
}

/* Padding Right Utilities */
.pr-025 {
  padding-right: 0.25rem;
}

.pr-05 {
  padding-right: 0.5rem;
}

.pr-075 {
  padding-right: 0.75rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-125 {
  padding-right: 1.25rem;
}

.pr-15 {
  padding-right: 1.5rem;
}

.pr-175 {
  padding-right: 1.75rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pr-3 {
  padding-right: 3rem;
}

.pr-4 {
  padding-right: 4rem;
}

.pr-5 {
  padding-right: 5rem;
}

.pr-10p {
  padding-right: 10px;
}

/* Margin Utilities */
.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0px;
}

.m-025 {
  margin: 0.25rem;
}

.m-05 {
  margin: 0.5rem;
}

.m-075 {
  margin: 0.75rem;
}

.m-1 {
  margin: 1rem;
}

.m-125 {
  margin: 1.25rem;
}

.m-15 {
  margin: 1.5rem;
}

.m-175 {
  margin: 1.75rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.m-4 {
  margin: 4rem;
}

.m-5 {
  margin: 5rem;
}

.m-6 {
  margin: 6rem;
}

.m-7 {
  margin: 7rem;
}

.m-8 {
  margin: 8rem;
}

.m-9 {
  margin: 9rem;
}

.m-10 {
  margin: 10rem;
}

.m-10p {
  margin: 10px;
}

/* Margin horizontal */
.mx-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

/* margin vertical */

.my-05 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-125 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-15 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-10p {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Margin Top Utilities */
.mt-0 {
  margin-top: 0rem;
}

.mt-025 {
  margin-top: 0.25rem;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mt-075 {
  margin-top: 0.75rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-125 {
  margin-top: 1.25rem;
}

.mt-15 {
  margin-top: 1.5rem;
}

.mt-175 {
  margin-top: 1.75rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mt-10p {
  margin-top: 10px;
}

/* Margin Left Utilities */

.ml-0 {
  margin-left: 0rem !important;
}

.ml-025 {
  margin-left: 0.25rem;
}

.ml-05 {
  margin-left: 0.5rem;
}

.ml-075 {
  margin-left: 0.75rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-125 {
  margin-left: 1.25rem;
}

.ml-15 {
  margin-left: 1.5rem;
}

.ml-175 {
  margin-left: 1.75rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.ml-4 {
  margin-left: 4rem;
}

.ml-5 {
  margin-left: 5rem;
}

.ml-10p {
  margin-left: 10px;
}

/* Margin Bottom Utilities */

.mb-025 {
  margin-bottom: 0.25rem;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.mb-075 {
  margin-bottom: 0.75rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-125 {
  margin-bottom: 1.25rem !important;
}

.mb-15 {
  margin-bottom: 1.5rem;
}

.mb-175 {
  margin-bottom: 1.75rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mb-10p {
  margin-bottom: 10px;
}

/* Margin Right Utilities */
.mr-025 {
  margin-right: 0.25rem;
}

.mr-05 {
  margin-right: 0.5rem;
}

.mr-075 {
  margin-right: 0.75rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-125 {
  margin-right: 1.25rem;
}

.mr-15 {
  margin-right: 1.5rem !important;
}

.mr-175 {
  margin-right: 1.75rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mr-3 {
  margin-right: 3rem;
}

.mr-4 {
  margin-right: 4rem;
}

.mr-5 {
  margin-right: 5rem;
}

.mr-10p {
  margin-right: 10px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-lg {
  font-size: 1.125rem;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

/* Text Size Utilities */
.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

/* Background and Color Utilities */
.bg-blue {
  background-color: blue;
}

.bg-green {
  background-color: green;
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}

.text-black-important {
  color: black !important;
}

.text-gray {
  color: #555;
}

.color-icon-primary {
  color: var(--color-icon-primary);
}

.filter-gray {
  filter: grayscale(100%);
}

.border {
  border: 1px solid #ddd;
}

.border-none {
  border: none;
}

.border-red {
  border: 1px solid red;
}

.border-blue {
  border: 1px solid var(--color-primitives-primary-600);
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.line-through {
  text-decoration: line-through;
}

/* Font Weight Utilities */
.font-thin {
  font-weight: 100;
}

.font-normal {
  font-weight: 400;
}

.font-base {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 900;
}

/* Sizing Utilities */
.w-full {
  width: 100%;
}

.min-w-max-content {
  min-width: max-content !important;
}
.h-full {
  height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

/* Display Utilities */
.hidden {
  display: none;
}

/* Position Utilities */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

/* z-index */

.z-1 {
  z-index: 1;
}

/* gap-x */

.gap-1 {
  gap: 1rem;
}

.gap-0125 {
  gap: 0.125rem;
}

.gap-025 {
  gap: 0.25rem;
}

.gap-05 {
  gap: 0.5rem;
}

.gap-075 {
  gap: 0.75rem;
}

.gap-2 {
  gap: 2rem;
}

/* Space-x */
.space-x-025 > * + * {
  margin-left: 0.25rem;
}

.space-x-1 > * + * {
  margin-left: 0.5rem;
}

.space-x-075 > * + * {
  margin-left: 0.75rem;
}

.space-x-2 > * + * {
  margin-left: 1rem;
}

.space-x-3 > * + * {
  margin-left: 1.5rem;
}

.space-x-4 > * + * {
  margin-left: 2rem;
}

.space-x-5 > * + * {
  margin-left: 2.5rem;
}

.space-x-6 > * + * {
  margin-left: 3rem;
}

.space-x-7 > * + * {
  margin-left: 3.5rem;
}

.space-x-8 > * + * {
  margin-left: 4rem;
}

/* Space-y */

.space-y-1 > * + * {
  margin-top: 0.5rem;
}

.space-y-04 > * + * {
  margin-top: 0.4rem;
}

.space-y-2 > * + * {
  margin-top: 1rem;
}

.space-y-3 > * + * {
  margin-top: 1.5rem;
}

.space-y-4 > * + * {
  margin-top: 2rem;
}

.space-y-5 > * + * {
  margin-top: 2.5rem;
}

.space-y-6 > * + * {
  margin-top: 3rem;
}

.space-y-7 > * + * {
  margin-top: 3.5rem;
}

.space-y-8 > * + * {
  margin-top: 4rem;
}

/* Text Transform */

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.none {
  text-transform: none;
}

/* Figma Text Styles */

/* Heading */

.heading-sm {
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
}

/* Title */

.title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

.title-md {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.title-sm {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}

/* Body */

.body-md {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  line-height: 19.5px;
}

.body-sm {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

.modal-title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
}

/* Label */

.label-lg {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.label-md {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.label-sm {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  line-height: 15px;
}

/* Button */

.button-md {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}

/* float */

.float-right {
  float: right;
}

.float-left {
  float: left;
}

/* overflow */

.overflow-anywhere {
  overflow-wrap: anywhere;
}

.break-word {
  word-break: break-word;
}

/* opacity */
.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

/* colors */

.primary-30 {
  color: var(--color-primitives-primary-600);
}

.primary-20 {
  color: var(--temp-color-primitives-primary-800);
}

.bg-primary-20 {
  background: var(--temp-color-primitives-primary-800);
}

.primary-95 {
  color: var(--color-primitives-primary-50);
}

.bg-primary-95 {
  background: var(--color-primitives-primary-50);
}

.bg-replacement {
  background: var(--color-primitives-primary-50);
}

.bg-white {
  background: #ffffff;
}

.secondary-20 {
  color: #3f2e00;
}

.bg-secondary-20 {
  background: #3f2e00;
}

.neutral-80 {
  color: #787b97;
}

.neutral-50 {
  color: #666899;
}

.bg-neutral-50 {
  background: #666899;
}

.neutral-60 {
  color: #918f9a;
}

.neutral-60a {
  color: #7c7eb6;
}

.bg-neutral-60a {
  background: #7c7eb6;
}

.bg-neutral-60 {
  background: #918f9a;
}

.neutral-30 {
  color: #3d3e5c;
}

.bg-neutral-30 {
  background: #3d3e5c;
}

.neutral-20 {
  color: #292a3d;
}

.bg-neutral-20 {
  background: #292a3d;
}

.error-50 {
  color: #de3730;
}

.bg-error-50 {
  background: #de3730;
}
