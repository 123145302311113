@import "styles/Typography.scss";
@import "styles/Colors.scss";

.customer-onboarding-desktop-view-main-div {
  display: flex;
  min-height: calc(100vh - 80px);
  width: 276px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  position: relative;
  background: var(--color-surface-primary);
}

.desktop-stepper {
  display: flex;
  flex-direction: column;
}

.need-help-text-container {
  h3 {
    margin: 0px;
  }
}

.custom-step-label {
  .MuiStepLabel-iconContainer {
    .MuiStepIcon-root {
      width: 24px;
      height: 24px;
    }
  }
}

.custom-step-label.active .MuiStepIcon-root {
  color: var(--color-surface-secondary-inverse);
}

.custom-step-label.inactive .MuiStepIcon-root {
  color: var(--color-brand-primary-grey-400);
}

.custom-step-label.completed .MuiStepIcon-root {
  color: var(--color-primitives-success-600);
}

.stepper-content {
  &.active {
    opacity: 1;
  }

  &.inactive {
    opacity: 0.6;
  }
}

.stepper-label {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
}

.onboarding-mobile-view-top-bar {
  display: flex;
  padding: 10px 10px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.onboarding-mobile-view-main-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.mobile-step-indicator {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  .progress-ring {
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(-90deg);
    }

    .progress-ring-circle-bg {
      stroke: #e0e0e0;
    }

    .step-count {
      @extend .para-body-s-medium;
      position: relative;
      z-index: 1;
      color: var(--color-text-body-secondary);
      text-align: center;
    }
  }
}
