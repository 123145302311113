.onboarding-payment-success-container {
  padding-top: 134px;
  max-width: 500px;
  margin-inline: auto;

  .payment-check-circle {
    padding: 0px !important;
    width: 40px !important;
    height: 40px !important;
    color: var(--color-primitives-success-600) !important;
  }

  .payment-dollar-circle {
    width: 40px !important;
    height: 40px !important;
    border-radius: 999px;
    padding: 10px;
    background-color: var(--color-surface-primary) !important;
    color: var(--color-icon-secondary) !important;
  }

  .onboarding-payment-container {
    padding-top: 32px;
  }

  .onboarding-payment-list {
    display: flex;
    gap: 12px;
    padding: 16px;
    margin-top: 12px;
    border-radius: 8px;
    background: var(--color-surface-primary-hover) !important;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 24px 20px;
    padding-bottom: 150px;
  }
}

.onboarding-payment-success-button {
  border-top: 1px solid var(--color-border-primary);
  padding: 12px 0 12px 0;
  display: flex;
  width: calc(100% - 315px);
  justify-content: flex-end;
  position: fixed;
  bottom: 0px;
  background: white;
}

.onboarding-payment-success-button-container {
  padding: 0 16px;
}

@media (max-width: 768px) {
  .onboarding-payment-success-button {
    width: 100%;
  }
}
