@import "styles/Colors.scss";
@import "styles/Typography.scss";

.radio-button {
  &__container {
    display: flex;
    height: 40px;
    padding: 0px 8px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--color-brand-primary-grey-200);

    &:hover {
      border-color: var(--color-border-primary-hover);
      @extend .color-surface-secondary;
    }

    &--checked {
      border-color: var(--color-border-primary-hover);
      @extend .color-surface-secondary;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  :global {
    .MuiRadio-root {
      &.Mui-checked {
        color: var(--color-border-primary-hover);
      }

      .css-1hbvpl3-MuiSvgIcon-root {
        width: 20px !important;
        height: 20px !important;
      }
    }

    .MuiFormControlLabel-root {
      .MuiTypography-root {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
