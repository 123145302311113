.label-terms {
  font-family: "Arial" !important;
  font-size: 16px !important;
  text-align: justify;
}

.terms-list-title {
  font-family: "Arial" !important;
  font-size: 24px;
}

.terms-list-items {
  font-family: "Arial" !important;
  font-size: 16px !important;
  margin-left: 1.5rem !important;
}

.terms-list-number {
  padding-top: 4px;
}

.terms-list-text {
  font-family: "Arial" !important;
  font-size: 16px !important;
}

.terms-list-text2 {
  font-family: "Arial" !important;
  font-size: 16px !important;
}

.link-class {
  font-family: "Arial";
}

b {
  font-family: "Arial";
}

.terms-container {
  overflow: auto;
  height: 100vh;
}
