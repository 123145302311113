.manage-timeoff-wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
}

.manage-timeoff-wrapper-admin {
  background: #fff;
  display: flex;
  flex-direction: column;
}

.manage-timeoff-container {
  width: 64.5rem;
  margin: 0 auto;
}

.timeoff-year {
  border-radius: 26px;
  background: var(--color-primitives-primary-100);
  display: flex;
  padding: 2px 11px;
  align-items: center;
  gap: 8px;
  color: var(--color-primitives-primary-900);
}

.timeoff-subheading {
  color: #292a3d;
  font-family: Poppins-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.timeoff-subheading-margin {
  margin-bottom: 0.6rem;
}

.leave-type-cards-container {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  white-space: nowrap;
}

.upcoming-leaves-margin {
  margin: 1rem auto;
}
