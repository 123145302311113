.modal-amount-container-wrapper {
  border-bottom: 1px solid var(--color-border-primary);
}

.modal-amount-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 1px solid var(--color-border-primary);
  background: var(--color-surface-primary-hover);
}

.modal-amount-fee-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
}

.amount-fee-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text-body-primary);
}

.modal-amount-fee-item-icon {
  width: 14px !important;
  height: 14px !important;
  margin-left: 4px;
  color: var(--color-text-body-primary);
}
