.typo-customer-name {
  color: var(--color-primitives-primary-900);
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  display: none;
}

/*https://stackoverflow.com/questions/43186015/css-hide-scroll-bar-but-have-element-scrollable*/
.disable-scrollbars::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}

.profile-down-btn {
  padding: 0px 10px;
  cursor: pointer;
}

.notification-btn {
  padding: 0px 10px;
  cursor: pointer;
}

.grid-customer-logo {
  padding: 0px 20px;
  display: none;
}

.toggle-btn {
  /* padding: 5px; */
  /* margin-right: 5px; */
  width: 18px;
  bottom: 12px !important;
  left: 229px !important;
  cursor: pointer;
}

.app-bar-grid1 {
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  height: 46px;
}

.inputSearch .MuiInputBase-root input::placeholder {
  color: #b2b4e2 !important;
  opacity: 1 !important;
}

.app-bar-grid {
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
}
.edge-sidebar-logo {
  justify-content: center;
  margin-top: 15px;
  margin: auto;
}
.main-box {
  padding: 0px 0px 0px 0px !important;
  width: 100%;
  /* position: absolute; */
}

.selected-left-menu {
  background: var(--color-primitives-primary-500);
}

.selected-left-menu-rebrand {
  background: var(--color-primitives-primary-800);
}

.app-sidebar-logo {
  margin-top: 33px;
  margin-left: 21px;
}

.app-sidebar-logo img {
  width: 173px;
  height: 70px;
  object-fit: cover;
  border-radius: 10.95px;
}
.app-sidebar-company-name {
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 12.775px;
  line-height: 19px;
  color: #fafaff;
  margin-top: 23px;
  margin-left: 21px;
}
.app-sidebar-start-date {
  margin-left: 21px;
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 10.95px;
  line-height: 16px;
  color: #fafaff;
  opacity: 0.4;
}

.app-sidebar-home-menu {
  padding: 8.45px 0px 9.57px 0px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  margin-top: 24px;
  cursor: pointer;
}

.app-sidebar-home-menu img {
  width: 18px;
  height: 17px;
  margin-right: 21px;
  margin-left: 21px;
}
.app-sidebar-home-menu-title {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #fafaff;
}
.sidebar-menu-category {
  margin-left: 21px;
  margin-top: 22px;
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 11.8625px;
  line-height: 18px;
  color: #fafaff;
  padding-bottom: 8px;
}

.bubble-popup {
  position: absolute;
  right: 28px;
  bottom: 35px;
  z-index: 10000;
}

.bubble-popup img {
  border: 5px solid var(--color-primitives-primary-600);
  border-radius: 199px;
  cursor: pointer;
  filter: drop-shadow(4.56522px 6.08696px 44.1304px rgba(6, 14, 100, 0.34));
}

.bubble-popup .bubble-content {
  position: absolute;
  bottom: 100px;
  right: 0px;
  background-color: white;
  padding: 20px 15px 20px;
  border-radius: 20px;
  box-shadow: -12.5486px 3.3463px 90px rgba(61, 67, 187, 0.38);
  width: 216px;
}

.bubble-popup .bubble-content .hand-wave {
  font-size: 28px;
  margin-bottom: 10px;
}

.bubble-popup .bubble-content p {
  margin: 0;
  font-family: "Poppins-Regular";
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #14151f;
  margin-bottom: 20px;
}

.bubble-popup .bubble-content .contact {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.bubble-content .contact span {
  font-size: 11px;
  font-family: "Poppins-Regular";
  line-height: 18px;
  color: #14151f;
  opacity: 0.5;
}

.bubble-content .contact div {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}

.bubble-content .contact div p {
  font-size: 14px;
  font-family: "Poppins-Regular";
  line-height: 20px;
  width: 142px;
  word-wrap: break-word;
  margin-bottom: 0;
}

.bubble-content .cross-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.bubble-content .tip-down {
  position: absolute;
  bottom: -22px;
  right: 22px;
}

.bubble-content .contact div button {
  padding: 4px 6px;
  border: none;
  border-radius: 6px;
  background-color: var(--color-primitives-primary-50);
  color: var(--color-primitives-primary-500);
  font-size: 10px;
  font-family: "Poppins-Medium";
  cursor: pointer;
}

.bubble-content .contact div button:active {
  box-shadow: 0px 0px 10px -3px rgba(73, 80, 199, 0.75);
}

/**Mobile first css- Override for larger screens*/
@media only screen and (min-width: 600px) {
  .typo-customer-name {
    display: inherit;
  }

  .grid-customer-logo {
    display: inherit;
  }

  .profile-down-btn {
    padding: 0px 20px;
  }

  .notification-btn {
    padding: 0px 30px;
  }

  .app-bar-grid {
    padding: 0px 20px;
  }
  .main-box {
    padding: 0px 0px 0px 0px !important;
    position: inherit;
  }
}

.collapsed-sidebar-menuitem {
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.collapsed-sidebar-menuitem:hover {
  background: var(--color-primitives-primary-800);
}

.left-menu-list-item:hover {
  background: var(--color-primitives-primary-800);
}

.left-menu-list-item-rebrand:hover {
  background: unset !important;
}

.appsidebar-menus-bottom-border {
  border: 0.4px solid var(--color-primitives-primary-400);
}

.appsidebar-menus-bottom-border-rebrand {
  border: 1px solid var(--color-primitives-primary-800);
}

.sidebar-icon svg path {
  fill: var(--color-primitives-primary-300);
}

.collapsed-sidebar-menuitem-rebrand svg path {
  fill: var(--color-primitives-primary-300);
}

.collapsed-sidebar-menus-bottom-border {
  width: 66px;
  height: 0px;
  border: 0.4px solid var(--color-primitives-primary-400);
  margin-bottom: 8px;
}

.collapsed-sidebar-menus-bottom-border-rebrand {
  border: 1px solid var(--color-primitives-primary-800);
}

.appsidebar-menu-title-text {
  font-family: "Poppins-Regular";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  padding: 4px 0px;
}
