@import "styles/Colors.scss";
@import "styles/Typography.scss";
@import "styles/BorderRadius.scss";
@import "styles/BoxShadows.scss";

.notifications-container {
  position: relative;
  &:hover,
  &.active {
    background-color: var(--color-surface-primary-pressed);
  }
  border-radius: var(--border-radius-full);
  padding: 5px;
  display: flex;
  align-items: center;
  color: var(--color-brand-primary-grey-400);
  justify-content: center;
  height: 34px;
  width: 34px;

  .MuiSvgIcon-root {
    font-size: 24px;
  }

  > div {
    > div:first-child {
      margin-top: 0px;
      margin-right: 0px;
    }

    #ss-notification-container {
      margin-right: 12px;
      margin-top: 12px;
    }
  }

  .notification-menu {
    position: absolute;
    top: calc(100% + 8px);
    right: -10px;
    width: 244px;
    background-color: var(--color-surface-primary);
    border-radius: var(--border-radius-md);
    overflow: hidden;
    z-index: 1200;

    .notification-item {
      display: flex;
      align-items: flex-start;
      padding: 10px 15px;

      &:hover {
        background-color: var(--color-primitives-neutral-50);
      }

      .notification-dot {
        font-size: 12px;
        margin-top: 4px;
        margin-right: 10px;
        color: var(--color-primitives-primary-600);
      }

      .notification-text {
        color: var(--color-text-body-secondary);
      }
    }
  }
}
