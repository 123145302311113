.employee-peers-listA {
  background: #ffffff;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.employee-peers-listA:nth-child(even) {
  background: #fbfaff;
}

.employee-peers-listA:nth-child(3) {
  border-radius: 24px 24px 0 0;
}

/* Style for the last element with classname employee-peers-listA */
.employee-peers-listA:last-child {
  border-radius: 0 0 24px 24px;
}

.employee-peers-listA .common-grid-container {
  border: none;
}

.employee-peers-cella {
  display: flex;
  gap: 5px;
  /* margin-right: 20px !important; */
  position: relative;
}

.employee-peers-cella img {
  width: 135px;
  height: 8.5rem;
  object-fit: cover;
  border-radius: 12px 0 0 12px;
}

.peer-name {
  font-family: Poppins-Medium !important;
  color: var(--neutral-20, #292a3d);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.peer-title {
  font-family: Poppins-Medium !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.peer-join-date {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-wrap: nowrap;
}

.peer-country {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin: 10px 0 10px 0;
  color: var(--color-primitives-primary-600);
  width: max-content;
}

.employee-peers-cellb {
  display: flex;
  gap: 5px;
  align-items: center;
  min-width: 300px;
  width: 40%;
}

.employee-email {
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  width: 300px;
  word-wrap: break-word;
  color: black;
  display: block;
  text-decoration: none;
}

.employee-contact-btn {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--color-primitives-primary-500);
}

.employee-peers-cellc {
  gap: 5px;
  width: 20%;
}

.employee-birthday {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--color-primitives-primary-500);
}

.employee-peers-celld {
  border: 1px solid grey;
  border-radius: 24px;
  padding: 5px 16px;
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #14151f;
  width: max-content;
  margin-left: -2rem;
}

.employee-peers-celld-container {
  width: 11%;
}

.employee-peers-search-field {
  background: #ffffff;
  border: 1px solid #bfc2ff;
  border-radius: 24px;
  width: 200px;
  height: 35px;
}

.employee-paystubs-details-dAA img {
  width: 18px;
  height: 18px;
  align-self: center;
}
